import React, { useEffect, useState } from "react";
import {
  Button,
  Typography,
  Box,
  TextField,
  InputAdornment,
  IconButton,
  FormControl,
  InputLabel,
  OutlinedInput,
  FormHelperText,
} from "@mui/material";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import { display, flexbox } from "@mui/system";
import { useAppDispatch } from "../../../services/StoreHooks";
import useTranslatewrapper from "../../../components/useTranslateWrapper";

function PasswordWidget({ setPasswordData }) {
  const { translate } = useTranslatewrapper();
  
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const [isValidPasswordField, setIsValidPasswordField] = React.useState(false)
  const [isValidConfirmPasswordField, setIsValidConfirmPasswordField] = React.useState(false)

  const dispatch = useAppDispatch();
  const dispatchNotification = (typ, msg) => {
    dispatch({
      type: "SHOW_NOTIFICATION",
      data: {
        open: true,
        type: typ,
        message: msg,
      },
    });
  };

  let msg = "";
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const passwordChangeHandler = (e) => {
    const capitalAndNumberRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).*$/;
    const specialCharacterRegex = /[\W_]+/;

    setPassword(e.target.value.trim());
    if (e.target.value.trim().length === 0) {
      document.getElementById("password-validate-message").innerHTML =
        "Password can not be Empty";
      document.getElementById("password-validate-message").style.color = "red";
      setIsValidPasswordField(false)
    } else if (e.target.value.length < 8) {
      document.getElementById("password-validate-message").innerHTML =
        "Password must have atleast 8 characters";
      document.getElementById("password-validate-message").style.color = "red";
      setIsValidPasswordField(false)
    } else if (!capitalAndNumberRegex.test(e.target.value.trim())){
      document.getElementById("password-validate-message").innerHTML =
      "Password must contain at least one uppercase letter, one lowercase letter and one number";
      document.getElementById("password-validate-message").style.color = "red";
      setIsValidPasswordField(false)
    }else if (!specialCharacterRegex.test(e.target.value.trim())){
      document.getElementById("password-validate-message").innerHTML =
      "Password must contain at least one special character";
      document.getElementById("password-validate-message").style.color = "red";
      setIsValidPasswordField(false)
    } else if (confirmPassword && e.target.value.trim() !== confirmPassword) {
      document.getElementById("password-validate-message").innerHTML =""
      setIsValidPasswordField(true)
      document.getElementById("confirm-password-validate-message").innerHTML =
        "Passwords do not match";
      document.getElementById("confirm-password-validate-message").style.color =
        "red";
        setIsValidConfirmPasswordField(false)
    } else {
      if(document.getElementById("confirm-password-validate-message").innerHTML ==
      "Passwords do not match"){

        document.getElementById("confirm-password-validate-message").innerHTML =
          "";
        setIsValidConfirmPasswordField(true)
      }
      document.getElementById("password-validate-message").innerHTML = "";
      setIsValidPasswordField(true)
    }
  };

  const confirmPasswordChangeHandler = (e) => {
    setConfirmPassword(e.target.value.trim());
    if (e.target.value.trim().length === 0) {
      document.getElementById("confirm-password-validate-message").innerHTML =
        "Password can not be Empty";
      document.getElementById("confirm-password-validate-message").style.color =
        "red";
        setIsValidConfirmPasswordField(false)
    } else if (e.target.value.trim() !== password) {
      document.getElementById("confirm-password-validate-message").innerHTML =
        "Passwords do not match";
      document.getElementById("confirm-password-validate-message").style.color =
        "red";
        setIsValidConfirmPasswordField(false)
    } else {
      
      document.getElementById("confirm-password-validate-message").innerHTML =
        "";
        setIsValidConfirmPasswordField(true)
    }
  };

useEffect(() => {
  setPasswordData({
    Password: password,
    ConfirmPassword: confirmPassword,
    isValidPassword: (isValidPasswordField && isValidConfirmPasswordField),
  });
}, [
  isValidPasswordField,
  isValidConfirmPasswordField,
]);

  return (
    <Box sx={{ display: "flex", gap: "2.5%", width: "50vw" }}>
      <FormControl sx={{ width: "46.5%" }} variant="outlined">
        <InputLabel htmlFor="outlined-adornment-password">{translate('password')}*</InputLabel>
        <OutlinedInput
          id="outlined-adornment-password"
          type={showPassword ? "text" : "password"}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          required
          label="Password"
          value={password}
          onChange={(e) => {
            passwordChangeHandler(e);
          }}
        />
        <FormHelperText id="password-validate-message">{msg}</FormHelperText>
      </FormControl>

      <FormControl sx={{ width: "46.5%" }} variant="outlined">
        <InputLabel htmlFor="outlined-adornment-password">
        {translate('confirmPassword')}*
        </InputLabel>
        <OutlinedInput
          id="outlined-adornment-password"
          type={showConfirmPassword ? "text" : "password"}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowConfirmPassword}
                edge="end"
              >
                {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          label="Confirm Password"
          required
          value={confirmPassword}
          onChange={(e) => {
            confirmPasswordChangeHandler(e);
          }}
        />
        <FormHelperText id="confirm-password-validate-message">
          {msg}
        </FormHelperText>
      </FormControl>
    </Box>
  );
}

export default PasswordWidget;

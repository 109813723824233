import { Box, Stack, Typography, Card, CardHeader, CardContent, Switch, FormControl, Select, MenuItem,Tab, Tabs, TextField, Autocomplete, CircularProgress } from '@mui/material'
import React, { useState , useEffect, useMemo, useRef } from 'react'
import DataCard from '../MapPage/Data Card/DataCard'
import Mapcomponent from '../../components/MapComponent/MapComponent'
import { useNavigate,useParams,useLocation } from 'react-router-dom'
import { fetchDashboardMasterData, fetchEmpaneledData, fetchLabMasterData, fetchLabTestingQuantities, fetchProductionMasterData, fetchProductionMasterDataNew, fetchWarehouseAggregateData } from '../../services/FortificationService'
import { cloneDeep } from 'lodash'
import { useAppDispatch, useAppSelector } from '../../services/StoreHooks'
import KeycloakService from '../../services/KeycloakService'
import { withStyles } from '@mui/styles'
import moment from 'moment'
import UnitConverterWeight from '../../utils/UnitConverter'
import { AgGridReact } from 'ag-grid-react'
import GridItem from '../GridItem/GridItem'
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import BatchSearch from './BatchSearch'
import MapPageAgencyGrid from '../../components/ReportingGrids/MapPageAgencyGrid'
import { getDashboardDataForIndividualCell, getDashboardEmpaneledDataForIndividualCell } from '../../services/DashBoardService'
import { getSourceManufacturersForTarget, getTargetManufAggregates } from '../../services/Manage_service'
import PremixGrid from './PremixGrid'
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { MILLER_UI_TEXT } from '../../textConstant'
import { useTranslation } from 'react-i18next';
import '../../i18n'
// import { Tab, Tabs } from 'material-ui'

function DashboardPage() {

  const categoryNameToIdSelectorRedux = useAppSelector((state) =>
    state?.categoriesData?.categories
  );

  const categoryNameToIdMapRedux = useMemo(()=>{
    return (
      categoryNameToIdSelectorRedux.reduce(
      (acc, current) => {
        acc[current.name] = current.id.toString();
        return acc;
      },
      {}
    ))
  }, [categoryNameToIdSelectorRedux])

  const { t : translate, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };


  // useEffect(()=>{
  //   changeLanguage('hi')
  // }, [])

    // console.log('init')
    const [sideCardsData, setSideCardsData] = useState([])
    const [selectedTab, setSelectedTab] = useState('1');
  const [heading, setHeading] = useState([''])
  const [searchKeyWord, setSearchKeyWord] = useState("");
  const [hideAgencyDrilldowns, setHideAgencyDrilldowns] = useState(false)
  const [disableSwitchingTabs, setDisableSwitchingTabs] = useState(false)


    
    const [dashboardData, setDashboardData] = useState({
        
        PREMIX : {
            id: categoryNameToIdMapRedux['PREMIX'],
            producedQuantity: 0,
            inProductoinQuantity: 0,
            totalProduction: 0,
            dispatchedApproved: 0,
            availableTested: 0,
            availableNonTested: 0

        },
        FRK : {
            id: categoryNameToIdMapRedux['FRK'],
            producedQuantity: 0,
            inProductoinQuantity: 0,
            totalProduction: 0,
            dispatchedApproved: 0,
            availableTested: 0,
            availableNonTested: 0
        },
        MILLER : {
            id: categoryNameToIdMapRedux['MILLER'],
            producedQuantity: 0,
            inProductoinQuantity: 0,
            totalProduction: 0,
            dispatchedApproved: 0,
            availableTested: 0,
            availableNonTested: 0
        },
        // WAREHOUSE : {
        //     id: "9",
        //     producedQuantity: 0,
        //     inProductoinQuantity: 0
        // }
    });

    // const [empaneledProductionData, setEmpaneledProductionData] = useState({
    //     PREMIX : {
    //         id: categoryNameToIdMapRedux['PREMIX'],
    //         producedQuantity: 0,
    //         inProductoinQuantity: 0,
    //         totalProduction: 0,
    //         dispatchedApproved: 0,
    //         availableTested: 0,
    //         availableNonTested: 0

    //     },
    //     FRK : {
    //         id: categoryNameToIdMapRedux['FRK'],
    //         producedQuantity: 0,
    //         inProductoinQuantity: 0,
    //         totalProduction: 0,
    //         dispatchedApproved: 0,
    //         availableTested: 0,
    //         availableNonTested: 0
    //     },
    //     MILLER : {
    //         id: categoryNameToIdMapRedux['MILLER'],
    //         producedQuantity: 0,
    //         inProductoinQuantity: 0,
    //         totalProduction: 0,
    //         dispatchedApproved: 0,
    //         availableTested: 0,
    //         availableNonTested: 0
    //     },
    //     // WAREHOUSE : {
    //     //     id: "9",
    //     //     producedQuantity: 0,
    //     //     inProductoinQuantity: 0
    //     // }
    // })

    const [empaneledTestingData, setEmpaneledTestingData] = useState({
        PREMIX : {
            id: categoryNameToIdMapRedux['PREMIX'],
            approved: 0,
            rejected: 0,
            transit: 0
        },
        FRK : {
            id: categoryNameToIdMapRedux['FRK'],
            approved: 0,
            rejected: 0,
            transit: 0
        },
        MILLER : {
            id: categoryNameToIdMapRedux['MILLER'],
            approved: 0,
            rejected: 0,
            transit: 0
        }
        // WAREHOUSE : {
        //     id: "9",
        //     approved: 0,
        //     rejected: 0,
        //     transit: 0
        // }
    })

    const [testingData, setTestingData] = useState({
        PREMIX : {
            id: categoryNameToIdMapRedux['PREMIX'],
            producedQuantity: 0,
            inProductoinQuantity: 0,
            totalTested: 0,
            dispatchedLotsTested: 0,
            dispatchedLotsNonTested: 0,
            batchApprovedTested: 0,
            batchRejected: 0,
            totalDispatched:0,
            batchApproved: 0
        },
        FRK : {
            id: categoryNameToIdMapRedux['FRK'],
            producedQuantity: 0,
            inProductoinQuantity: 0,
            totalTested: 0,
            dispatchedLotsTested: 0,
            dispatchedLotsNonTested: 0,
            batchApprovedTested: 0,
            batchRejected: 0,
            totalDispatched:0,
            batchApproved: 0
        },
        MILLER : {
            id: categoryNameToIdMapRedux['MILLER'],
            producedQuantity: 0,
            inProductoinQuantity: 0,
            totalTested: 0,
            dispatchedLotsTested: 0,
            dispatchedLotsNonTested: 0,
            batchApprovedTested: 0,
            batchRejected: 0,
            totalDispatched:0,
            batchApproved: 0
        },
    });


    const [warehouseData, setWarehouseData] = useState({
        WAREHOUSE : {
        categoryId: parseInt(categoryNameToIdMapRedux['WAREHOUSE']),
        id: categoryNameToIdMapRedux['WAREHOUSE'],
        MILLER:{
          acceptedLots: 0,
          rejectedLots: 0,
          dispatchedLots: 0,
          availableLots: 0},
        FRK:{
          acceptedLots: 0,
          rejectedLots: 0,
          dispatchedLots: 0,
          availableLots: 0
        }
        }
    })

    const [gridsFlows, setGridsFlows] = useState({
      agencyFlow: [
        "agency-all",
        "agency-targetManufacturers",
        "agency-lotList",
      ],
      agencyReverseFlow: [
        "agency-all",
        "agency-sourceManufacturers",
        "agency-lotList"
      ]
    })

    const [query, setQuery] = useState({
      type: "",
      fromDate: "",
      toDate: "",
      // stateGeoId: 28,
      districtGeoId: "",
      categoryId: "",
      cellType: '',
      dataType: '',
      geoId: '',
      level:'',
      selectedPremixAgencyName:'',
      selectedFrkAgencyName:'',
      selectedMillerAgencyName:'',
      selectedWarehouseAgencyName:'',
      labId: '',
      labDataType: ''
    });

    const userDetails = KeycloakService.getUserDetail();
    const params = useParams();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const geoId = userDetails.stateGeoId || userDetails.districtGeoId || queryParams.get('geoId');
    const getLevel = (userDetails)=>{
        if(userDetails.stateGeoId){
            return 'state'
        }else if(userDetails.districtGeoId){
            return 'district';
        }else{
            return queryParams.get('level') || 'country';
        }
    }
    const level = getLevel(userDetails);
    const view = queryParams.get('view');
    const viewMap = {
        lab: 'testing',
        production: 'production'
    }
    const [dashboardType, setDashboardType] = useState('masters');
    const [masterCardClicked, setMasterCardClicked] = useState(false);
    const [categoryname, setCategoryname] = useState(
      params.categoryname.toUpperCase()
    );
    const [selectedCategoryId, setCategoryId] = useState(
      params.categoryId == 0 ? "" : params.categoryId
    );
    const [gridlevel, setGridlevel] = useState({
        level: level || "country",
        geoId: geoId || 1,
        name: "India",
      });
      const mapRef = useRef(null);
      const tableRef = useRef(null);
      const [showLabData, setShowLabData] = useState(false)
    const [activeCard, setActiveCard] = useState("");
    const startYear = "2023";
    const currentYear = new Date().getFullYear();
    const [selectedYear, setselectdYear] = useState(currentYear);
    const [yearsData, setYears]= useState([])
    const [selectedDates, setSelectedDates] = useState({
      startDate: moment().subtract(6, 'months').format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD')
    })
    const [isReportingGridLinkHidden, setIsReportingGridLinkHidden] = useState(true)
    const [stateName, setStateName] = useState("")
    const [columnDefs, setColumnDefs] = useState([
      //columns for source manufacturer grid
      {
        headerName: "S.No",
        valueGetter: "node.rowIndex + 1",
        width: 100,
        flex:0.5,
      },
      {
        headerName: "Agency Name",
        field: "manufacturerName",
        tooltipField: "manufacturerName",
        width: "auto",
        flex:2,
      },
      {
        headerName: "License Number",
        field: "licenseNo",
        tooltipField: "licenseNo",
        width: "auto",
        flex:1,
      },
      {
        headerName: "State",
        field: "stateName",
        width: "auto",
        flex:1,
      },
      {
        headerName: "District",
        field: "districtName",
        width: "auto",
        flex:1,
      },
      {
        headerName: "Data",
        field: "data",
        width: "auto",
        valueFormatter: (params) => {
          return UnitConverterWeight(params.data.data);
        },
        flex:1,
      }
    ]);
    const [rowData, setRowData] = useState([])


      const navigate=useNavigate();
      
        useEffect(()=>{
            // if(gridlevel.level !== 'district'){
                fetchMasterData(showLabData)
                scrollToMap();
                navigate({ search: `?geoId=${gridlevel.geoId}&level=${gridlevel.level}${showLabData ? '&view=lab' : `&view=`}` });
            // }
        },[gridlevel])

    useEffect(() => {
        const years = [];
        for (let year = startYear; year <= currentYear; year++) {
            years.push(year)
        }
        years.push("All")
        setYears(years)
    }, [])

    // useEffect(()=>{
    //   fetchMasterData()
    // }, [selectedYear])//start date and end date

    useEffect(()=>{
      if(['premix', 'frk', 'miller'].includes(params.categoryname.toLowerCase()) && queryParams.get('level') == 'state' ){
        setIsReportingGridLinkHidden(false)
      } else{
        setIsReportingGridLinkHidden(true)
      }
    },[params.categoryname, queryParams.get('level') ])

    const setData = (masterData, labData)=>{
        const data = []
        if("PREMIX" in masterData){
            data.push({
                categoryId:masterData["PREMIX"]?.categoryId,
                name: "PREMIX",
                count: masterData["PREMIX"]?.total,
                className: `premix-card`,
                clicked: dashboardType === 'masters' && params.categoryname?.toLowerCase() === 'premix'
    
            })
        }

        if("FRK" in masterData){
            data.push({
                categoryId:masterData["FRK"]?.categoryId,
                name: "FRK",
                count: masterData["FRK"]?.total,
                className: `frk-card`,
                clicked: dashboardType === 'masters' && params.categoryname?.toLowerCase() === 'frk'
    
            })
        }

        if("MILLER" in masterData){
            data.push({
                categoryId:masterData["MILLER"]?.categoryId,
                name: "MILLER",
                count: masterData["MILLER"]?.total,
                className: `miller-card`,
                clicked: dashboardType === 'masters' && params.categoryname?.toLowerCase() === 'miller'
    
            })
        }

        if("WAREHOUSE" in masterData){
            data.push({
                categoryId:masterData["WAREHOUSE"]?.categoryId,
                name: "WAREHOUSE",
                count: masterData["WAREHOUSE"]?.total,
                className: `warehouse-card`,
                clicked: dashboardType === 'masters' && params.categoryname?.toLowerCase() === 'warehouse'
    
            })
        }
        data.push({
            categoryId: 0,
            name: "LABS",
            count: labData && labData.data && labData.data.total || 0,
            className: `premix-card`,
            clicked: dashboardType === 'masters' && params.categoryname?.toLowerCase() === 'labs'

        })
            setSideCardsData(data)

    }
    const handleClick = (categoryId, categoryName, clicked) => {
        setSideCardsData((prevDivs) =>
          prevDivs.map((div) =>
            {
               return (div.categoryId === categoryId || div.categoryName === categoryName) ? { ...div, clicked: !div.clicked } : { ...div, clicked: false }
            }
          )
        );
        // setDashboardData(d=> ({...d}))

        // setTestingData(d => ({...d}))
        setWarehouseData(d=> ({...d}))
        // setEmpaneledTestingData(d=> ({...d}))
        setMasterCardClicked(!masterCardClicked)
        setDashboardType('masters')
        const name = clicked ? 'all': categoryName
        const id = clicked ? '' : categoryId;
        setCategoryname(name);
        setCategoryId(id);
        scrollToMap();
        navigate(`/admin/dashboard/${name.toUpperCase()}/${id || 0}?geoId=${gridlevel.geoId}&level=${gridlevel.level}&view=`)

    };
    const dispatch = useAppDispatch();
    const dispatchShowLoader = (showLoader) => {
        dispatch({
          type: "SHOW_LOADER",
          data: {
            showLoader
          },
        });
      };
    
    // const fromDateYear = Number(selectedYear) ? selectedYear : startYear
    // const toDateYear = Number(selectedYear) ? Number(selectedYear) + 1 : currentYear + 1
    // const fromDate = new Date(fromDateYear, 0, 2);
    // const toDate = new Date(toDateYear, 0, 1);
    // const formattedfromDate = fromDate.toISOString().slice(0,10);
    // const formattedtoDate = toDate.toISOString().slice(0,10);

    const categoryNameMap = {
      [categoryNameToIdMapRedux['PREMIX']] : 'Premix',
      [categoryNameToIdMapRedux['FRK']]: 'FRK',
      [categoryNameToIdMapRedux['MILLER']]:'Miller',
      [categoryNameToIdMapRedux['WAREHOUSE']]:'Warehouse'
    }

    const categoryNameMapForHeadings = {
      [categoryNameToIdMapRedux['PREMIX']] : 'Premix',
      [categoryNameToIdMapRedux['FRK']]: 'FRK',
      [categoryNameToIdMapRedux['MILLER']]:'Miller(FR)',
      [categoryNameToIdMapRedux['WAREHOUSE']]:'Warehouse'
    }


    const categoryTargetNameMap = {
      [categoryNameToIdMapRedux['PREMIX']] : 'FRK',
      [categoryNameToIdMapRedux['FRK']]: 'Miller(FR)',
      [categoryNameToIdMapRedux['MILLER']]:'Warehouse',
    }

    const handleBackClick = () => {
      const currentType = query["type"];

      if(query['type'] === 'agency-batchesList'){
        setQuery((prevQuery) => ({
          ...prevQuery,
          type: 'agency-all',
        }));
        setSearchKeyWord("");
        return
      }

      const flowType = query['cellType'] === 'usedQuantity' ? 'agencyReverseFlow' : 'agencyFlow'

      let prevType = "";
      let currentTypeIndexInFlow =
        gridsFlows[flowType].indexOf(currentType);
      if (currentTypeIndexInFlow != 0 && currentTypeIndexInFlow != -1) {
        setQuery((prevQuery) => ({
          ...prevQuery,
          type: gridsFlows[flowType][
            currentTypeIndexInFlow - 1
          ],
        }));
      }
      setSearchKeyWord("");
    };
  

    const getDataOnQueryChange = async () => {

      getHeadingFromQuery(query)

      setHideAgencyDrilldowns(false)

      // dispatchShowLoader(true)

      try{

        if(query['type'] === 'agency-all'){
          let dataFromApi;

          if(selectedTab == '2'){
            dataFromApi = await getDashboardEmpaneledDataForIndividualCell(query['categoryId'], query['cellType'], query['fromDate'], query['toDate'])

          } else if(selectedTab == '1'){
            dataFromApi = await getDashboardDataForIndividualCell(query['categoryId'], query['cellType'],query['level'], query['geoId'], query['dataType'] , query['fromDate'], query['toDate'])
          }

          if(dataFromApi?.data?.length > 0){
            setHideAgencyDrilldowns(false)
          } else{
            setHideAgencyDrilldowns(true)
          }

          setRowData(dataFromApi?.data
            // .filter(ele=>(ele.data != 0))
          )
            if(scrollToTable){
                scrollToTable()
            }
        } else if(query['type'] === 'agency-targetManufacturers'){
          const dataFromApi = await getTargetManufAggregates(

            // query["districtGeoId"],
            query["sourceManufacturerId"],
            {
              fromDate: query["fromDate"],
              toDate: query["toDate"],
              categoryId: query['categoryId']
            },
            (selectedTab == '2'),
            userDetails.stateGeoId,
  
          );
          setRowData(dataFromApi?.data)
          if(scrollToTable){
            scrollToTable()
          }
        } else if(query['type'] === 'agency-sourceManufacturers'){
          const dataFromApi = await getSourceManufacturersForTarget(
            query["targetManufacturerId"],
            {
              fromDate: query["fromDate"],
              toDate: query["toDate"],
              categoryId: query['categoryId']
            },
            (selectedTab == '2'),
            userDetails.stateGeoId,
  
          );


          setRowData(dataFromApi?.data)
          if(scrollToTable){
            scrollToTable()
          }
        }


      } catch (err) {
        // dispatchShowLoader(false);
        console.log(err)
      }
      // dispatchShowLoader(false);

    }

    const getHeadingFromQuery = (query) => {
      switch (query['type']) {
        case 'agency-all':
          if(query['cellType'] == 'usedQuantity'){
            setHeading(categoryTargetNameMap[query['categoryId']] + ' Agencies that consumed ' + categoryNameMapForHeadings[query['categoryId']])
          } else{
            setHeading(categoryNameMapForHeadings[query['categoryId']] + ' Agencies')
          }
          break;
          
        case 'agency-targetManufacturers':
          setHeading(query[`selected${categoryNameMap[query['categoryId']]}AgencyName`]
            + ` agency dispatched ${categoryNameMapForHeadings[query['categoryId']]} lots to these ${categoryTargetNameMap[query['categoryId']].replace('Miller(FR)', 'Miller(FR)/Warehouse')} agencies`)
          break;

        case 'agency-sourceManufacturers':
          setHeading(categoryNameMapForHeadings[query['categoryId']] + ' Agencies that sent ' + categoryNameMapForHeadings[query['categoryId']] + " lots to " + query[`selected${categoryTargetNameMap[query["categoryId"]]}AgencyName`] + " agency")
          break;
        case 'agency-batchesList':
          setHeading(categoryNameMapForHeadings[query['categoryId']] + " Batches created by " + query[`selected${categoryNameMap[query['categoryId']]}AgencyName`]  )
          break;

  
        case 'agency-lotList':
          if(categoryNameMap[query['categoryId']] == 'Miller'){
            setHeading('FR Lot List')
          } else {
            setHeading(`${categoryNameMap[query['categoryId']]} Lot list`)
          }
          break;
  
        default:
          setHeading('')
          break;
      }
    }

    const onCellClicked = (params) => {
      if (params.colDef.field === 'numberOfBatches') {
        if(params.data.numberOfBatches === 0){
          return
        }
        setQuery((prevQuery)=>({
          ...prevQuery,
          sourceManufacturerId: params.data.manufacturerId,
          sourceManufacturerName: params.data.manufacturerName,
          [`selected${categoryNameMap[query['categoryId']]}AgencyName`] : params.data.manufacturerName,
          type: 'agency-batchesList'
        }))
      }
      setSearchKeyWord("");
    };


    const handleRowClick = (event) => {

      const cellElement = event.event.target.closest('.ag-cell');

      if (cellElement) {
        const columnId = cellElement.getAttribute('col-id');
        if(['numberOfBatches'].includes(columnId)){
          return;
        }
      }


      const selectedRowData = event.data;

      if (query["cellType"] == "usedQuantity") {
        if (query["type"] == "agency-all") {
          setQuery((prevQuery) => ({
            ...prevQuery,
            sourceManufacturerId: "",
            targetManufacturerId: selectedRowData.manufacturerId,
            type: "agency-sourceManufacturers",
            [`selected${categoryTargetNameMap[query["categoryId"]]}AgencyName`]:
              selectedRowData.manufacturerName,
          }));
        } else if (query["type"] == "agency-sourceManufacturers") {
          setQuery((prevQuery) => ({
            ...prevQuery,
            sourceManufacturerId: selectedRowData.manufacturerId,
            type: "agency-lotList",
          }));
        }
      } else {
        if (query["type"] == "agency-all") {
          setQuery((prevQuery) => ({
            ...prevQuery,
            sourceManufacturerId: selectedRowData.manufacturerId,
            targetManufacturerId: "",
            sourceManufacturerName: selectedRowData.manufacturerName,
            type: "agency-targetManufacturers",
            [`selected${categoryNameMap[query["categoryId"]]}AgencyName`]:
              selectedRowData.manufacturerName,
          }));
        } else if (query["type"] == "agency-targetManufacturers") {
          setQuery((prevQuery) => ({
            ...prevQuery,
            targetManufacturerId: selectedRowData.manufacturerId,
            targetManufacturerName: selectedRowData.agencyName,
            type: "agency-lotList",
          }));
        }
      }
      setSearchKeyWord("");
    };

    useEffect(()=>{
      getDataOnQueryChange()
    },[query])

    const getEmpaneledDataFromApis = async () => {
      try {

        const empaneledTestingDataFromApi = await fetchEmpaneledData(
          // formattedfromDate,
          // formattedtoDate,
          selectedDates.startDate,
          selectedDates.endDate,
          userDetails.stateGeoId,
          "testing",
          gridlevel.geoId,
          gridlevel.level
        );
        if (
          empaneledTestingDataFromApi &&
          empaneledTestingDataFromApi.status == "200"
        ) {
          let tempMap = {};
          for (let element of empaneledTestingDataFromApi.data) {
            tempMap = {
              ...tempMap,
              [element["categoryName"]]: element,
            };
          }

          const { WAREHOUSE, ...tempMapWithoutWarehouse } = tempMap;

          setEmpaneledTestingData(() => {
            return {
              ...tempMapWithoutWarehouse,
            };
          });
        }

        // dispatchShowLoader(false);
      } catch(err) {
        dispatchShowLoader(false);
      }
    };

    const getGeographicViewDataFromApis = async () => {
      try {
        // dispatchShowLoader(true);

        const productionMasterData = await fetchProductionMasterDataNew(
          "production",
          // formattedfromDate,
          // formattedtoDate,
          selectedDates.startDate,
          selectedDates.endDate,
          null,
          gridlevel.level,
          gridlevel.geoId,
          categoryNameToIdMapRedux
        );
        let tempMap = {};
        for (let element of productionMasterData.data) {
          tempMap = {
            ...tempMap,
            [element["categoryName"]]: element,
          };
        }
        setDashboardData((dashboardData) => {
          return {
            ...tempMap,
          };
        });
        const labTestingQuantitiesData = await fetchProductionMasterDataNew(
          "testing",
          // formattedfromDate,
          // formattedtoDate,
          selectedDates.startDate,
          selectedDates.endDate,
          null,
          gridlevel.level,
          gridlevel.geoId,
          categoryNameToIdMapRedux
        );
        if (
          labTestingQuantitiesData &&
          labTestingQuantitiesData.status == "200"
        ) {
          let tempMap = {};
          for (let element of labTestingQuantitiesData.data) {
            tempMap = {
              ...tempMap,
              [element["categoryName"]]: element,
            };
          }

          setTestingData(() => {
            return {
              ...tempMap,
            };
          });
        }

        // dispatchShowLoader(false);
      } catch(err) {
        console.log(err, 'err')
        dispatchShowLoader(false);
      }
    };

    const getWarehouseDataFromApi = async (selectedTabVal) =>{
        try{
            // dispatchShowLoader(true);

            let warehouseAggregateData;


              warehouseAggregateData = await fetchWarehouseAggregateData(
                // formattedfromDate, formattedtoDate, 
                selectedDates.startDate, selectedDates.endDate,
                parseInt(categoryNameToIdMapRedux['MILLER']), gridlevel.level, gridlevel.geoId, selectedTabVal)


            if (
                warehouseAggregateData &&
                warehouseAggregateData.status == "200"
              ) {
                setWarehouseData({
                    WAREHOUSE : {
                        categoryId: parseInt(categoryNameToIdMapRedux['WAREHOUSE']),
                        id: categoryNameToIdMapRedux['WAREHOUSE'],
                        FRK:{
                          acceptedLots: warehouseAggregateData.data.FRK['accepted'],
                          rejectedLots: warehouseAggregateData.data.FRK['rejected'],
                          dispatchedLots: warehouseAggregateData.data.FRK['dispatched'],
                          availableLots: warehouseAggregateData.data.FRK['available']  
                        },
                        MILLER:{
                          acceptedLots: warehouseAggregateData.data.MILLER['accepted'],
                          rejectedLots: warehouseAggregateData.data.MILLER['rejected'],
                          dispatchedLots: warehouseAggregateData.data.MILLER['dispatched'],
                          availableLots: warehouseAggregateData.data.MILLER['available']
                        }
                        }
                })
              }


            // dispatchShowLoader(false);
        } catch(err){
            dispatchShowLoader(false);
        }
    }

    const getRowStyle = (params) => {
      if (params.node.rowIndex % 2 != 0) {
        return { background: "#F8F8F8" };
      }
    };

    const defaultColDef = useMemo(() => {
      return {
        resizable: true,
      };
    }, []);

  let overlayNoRowsTemplate = '<span style="padding: 10px; display: block; text-align: center;">No Data to show</span>'

    const gridOptions = {
      overlayNoRowsTemplate: overlayNoRowsTemplate,
    };

    const handleDateSubmit = () => {
      setQuery((query)=>({
        ...query,
        'fromDate': selectedDates['startDate'],
        'toDate': selectedDates['endDate']
      }))
      fetchMasterData()
    }

    const fetchMasterData = async(labView= false)=> {
        try{
            dispatchShowLoader(true)
            const masterData = await fetchDashboardMasterData({type: gridlevel.level, geoId: gridlevel.geoId
              // , year: selectedYear
             , startDate: selectedDates['startDate'], endDate: selectedDates['endDate']
            }, selectedTab);
            const labMasteData = await fetchLabMasterData({type: gridlevel.level, geoId: gridlevel.geoId
              // , year: selectedYear 
             , startDate: selectedDates['startDate'], endDate: selectedDates['endDate']
            })//here
            if(masterData.status == '200'){
                setData(masterData.data, labMasteData)
            }
   

            if(selectedTab == '1'){
              await getGeographicViewDataFromApis()
            } else if(selectedTab == '2'){
              await getEmpaneledDataFromApis()
            }
            await getWarehouseDataFromApi(selectedTab)

            dispatchShowLoader(false);
        }catch{
            dispatchShowLoader(false)
        }

    }
    const scrollToMap = ()=>{
        
       setTimeout(()=>{
        mapRef?.current?.scrollIntoView({ behavior: 'smooth' });
       },100) 

    }

    const scrollToTable = ()=>{
        
      setTimeout(()=>{
       tableRef?.current?.scrollIntoView({ behavior: 'smooth' });
      },200) 

   }


    const memoizedMapComponent = useMemo(() => (
        <Mapcomponent
          dashboardType={(selectedTab == '2') ? (((dashboardType == 'production') && (categoryname != 'WAREHOUSE') ) ? 'testing' : dashboardType) : dashboardType }
          selectedCategoryId={selectedCategoryId}
          categoryname={categoryname}
          setGridlevel={setGridlevel}
          geoId={geoId}
          setStateName={setStateName}
          // year={selectedYear}
          selectedDates={selectedDates}
          gridlevel={gridlevel}
          // formattedfromDate={formattedfromDate}
          // formattedtoDate={formattedtoDate}
          // startDate={selectedDates.startDate}
          // endDate={selectedDates.endDate}
          setDisableSwitchingTabs={setDisableSwitchingTabs}
          selectedTab={selectedTab}
        />
      ), [dashboardType, selectedCategoryId, categoryname, gridlevel, geoId, 
        // selectedYear, 
        // formattedfromDate, formattedtoDate, 
        selectedTab, selectedDates
      ]);
    const onCardClick = (categoryId,categoryName, isLab)=>{
      // setRowData([])
      setHideAgencyDrilldowns(true)

        if(isLab){
            setDashboardData(d=> ({...d}))
            setTestingData(d => ({...d}))
            setWarehouseData(d=> ({...d}))
        }else{
          setDashboardData(d=> ({...d}))

            setTestingData(d => ({...d}))
            setWarehouseData(d=> ({...d}))
            setEmpaneledTestingData(d=> ({...d}))

        }
        setCategoryId(categoryId);
        setCategoryname(categoryName);
        setDashboardType(isLab ? 'testing': 'production');
        setSideCardsData(d=> {
            d.forEach(item=> item.clicked = false);
            return d
        })
        //set warehouse card clicked as false
        navigate(`/admin/dashboard/${categoryName.toUpperCase()}/${categoryId || 0}?geoId=${gridlevel.geoId}&level=${gridlevel.level}&view=${categoryId ? (isLab ? 'lab' : 'production') : ''}`);
        scrollToMap();
    }

    const handleChange = async (event, newValue) => {
      // setDisableSwitchingTabs(true)
      setHideAgencyDrilldowns(true)
      setDashboardData(d=> ({...d}))

      setTestingData(d => ({...d}))
      setWarehouseData(d=> ({...d}))
      setEmpaneledTestingData(d=> ({...d}))

        // await fetchMasterData()
        if(newValue == '2'){
          setGridlevel({
            level: "country",
            geoId: 1,
            name: "India",
          })
          // setDashboardType('testing')
            await getEmpaneledDataFromApis()
        } else if(newValue == '1'){
          setGridlevel({
            level: level || "country",
            geoId: geoId || 1,
            name: "India",
          })
            await getGeographicViewDataFromApis()
        }
        // navigate(`/admin/dashboard/all/0?geoId=${gridlevel.geoId}&level=${gridlevel.level}&view=`);
        setSelectedTab(newValue);
        setTestingData({...testingData})
        await getWarehouseDataFromApi(newValue)
        dispatchShowLoader(false)
        // setDisableSwitchingTabs(false)


    };
    
    return (
        <div className="main-content-overlay">
            { userDetails.stateGeoId &&
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={selectedTab} onChange={handleChange}>
                    <Tab value={'1'} disabled={disableSwitchingTabs} label="Geographical View" />
                    <Tab value={'2'} disabled={disableSwitchingTabs} label="Empanelment View"  />
                </Tabs>
            </Box>
            }
            <Box className="main-content-div" style={{ position: "relative" }}>
              <div style={{display: 'flex', flexDirection:'column', marginTop: '15px'}}>
            {/* <div style={{alignSelf: 'flex-end'}}>
            <FormControl sx={{marginRight: "40px", width: "80px"}}  variant="standard">
                      {yearsData && yearsData.length >0 && <Select
                        sx={{
                          fontSize: "12px",
                          color: "black",
                        }}
                        value={selectedYear}
                        onChange={(e)=>{
                          console.log(e, 'e')
                            // setCategoryId('');
                            // setCategoryname('all');
                            setselectdYear(e.target.value);
                        }}
                      > {
                        // yearsData?.map((year)=>{
                                yearsData.map(year => (
                                    <MenuItem
                                        sx={{
                                            fontSize: "12px",
                                            color: "black",
                                        }}
                                        key={year}
                                        value={year}
                                    >
                                        {year}
                                    </MenuItem>
                                ))
                          
                        // })
                      }
                      </Select>}
                    </FormControl>
            </div> */}


          <div style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>

            <div style={{alignSelf: 'center', display: 'flex', flexDirection:'row', marginTop:'10px',marginLeft:'10px', paddingBottom:'5px'}}>
              <div
                style={{
                  marginRight: "30px",
                  height: "auto",
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileDatePicker
                    value={selectedDates['startDate']}
                    maxDate={selectedDates['endDate']}
                    disableFuture={true}
                    label={"Start Date"}
                    // inputFormat="YYYY/MM/DD"
                    inputFormat="DD/MM/YYYY"
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        {...params}
                        inputProps={{ ...params.inputProps, readOnly: true }}
                      />
                    )}
                    onChange={(date) => {
                      // setStartDate(date.$d);
                      setSelectedDates({
                        ...selectedDates,
                        // startDate : date.$d.toISOString().slice(0,10)
                        startDate: moment(date.$d).format("YYYY-MM-DD")
                      })
                      // console.log(date.$d, 'date.$d')
                    }}
                    className="input-date-picker"
                    // sx={{ mb: "1", mt: "1",width: "97%" }}
                  />
                </LocalizationProvider>
              </div>

              <div
                style={{
                  marginRight: "40px",
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileDatePicker
                    value={selectedDates['endDate']}
                    disableFuture={true}
                    minDate={selectedDates['startDate']}
                    label={"End Date"}
                    // inputFormat="YYYY/MM/DD"
                    inputFormat="DD/MM/YYYY"
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        {...params}
                        inputProps={{ ...params.inputProps, readOnly: true }}
                      />
                    )}
                    onChange={(date) => {
                      // setEndDate(date.$d);
                      setSelectedDates({
                        ...selectedDates,
                        // endDate : date.$d.toISOString().slice(0,10)
                        endDate: moment(date.$d).format("YYYY-MM-DD")
                      })
                    }}
                    className="input-date-picker"
                    // sx={{paddingTop: '10px'}}
                    // sx={{ mb: "1", mt: "1",width: "97%" }}
                  />
                </LocalizationProvider>
              </div>
              <button
                className="btn btn-outline-primary "
                onClick={handleDateSubmit}
                style={{ marginRight: "15px" }}
              >
                submit
              </button>

            </div>

            <div style={{margin: '15px 10px 0px 5px', alignSelf: 'flex-end', width: '30%'}}>
                  <BatchSearch />
                  </div>

          </div>


                <div style={{display: 'flex', flexDirection: 'column', alignItems:'center'}}>

                <div style={{width: '100%'}}>


                <div style={{padding: "20px 0 0 30px" , display: 'flex', alignItems: "center"}}>

                <Typography sx={{flexGrow: 1}} hidden={selectedTab == '2'} >Production Data</Typography>
                </div>
                {
                  (selectedTab == 1) && 
                  <div className="mb-2"> <DataCard setRowData={setRowData} selectedTab={selectedTab} dashboardData={dashboardData} params={params} type={"production"} dashboardType={dashboardType} masterCardClicked={masterCardClicked} onCardClick={(id, cName)=> onCardClick(id, cName, false)} setCategoryId={setCategoryId} setDashboardType={setDashboardType} scrollToTable={scrollToTable} 
                  // formattedfromDate={selectedYear !== 'All' ? moment(new Date(selectedYear, 0, 1)).format('YYYY-MM-DD') : moment(new Date(startYear, 0, 1)).format('YYYY-MM-DD')}
                  // formattedtoDate={selectedYear !== 'All' ? moment(new Date(selectedYear, 11, 31)).format('YYYY-MM-DD') : moment(new Date(currentYear, 11, 31)).format('YYYY-MM-DD')}
                  startDate={selectedDates.startDate}
                  endDate={selectedDates.endDate}
                  setQuery={setQuery} query={query}
                  gridlevel={gridlevel}
                  ></DataCard>
                </div>}

                {
                <div hidden={selectedTab == '2'} style={{padding: "0 0 0 30px" , display: 'flex', alignItems: "center"}}>
                    <Typography >Testing Data</Typography>
                </div>
                }  
                { 
                (selectedTab == 1) &&
                <div> <DataCard setRowData={setRowData} selectedTab={selectedTab} dashboardData={testingData} type={"lab"} params={params} dashboardType={dashboardType} masterCardClicked={masterCardClicked} onCardClick={(id, cName)=>{onCardClick(id, cName, true)}} setCategoryId={setCategoryId} setDashboardType={setDashboardType} scrollToTable={scrollToTable}
                  // formattedfromDate={selectedYear !== 'All' ? moment(new Date(selectedYear, 0, 1)).format('YYYY-MM-DD') : moment(new Date(startYear, 0, 1)).format('YYYY-MM-DD')}
                  // formattedtoDate={selectedYear !== 'All' ? moment(new Date(selectedYear, 11, 31)).format('YYYY-MM-DD') : moment(new Date(currentYear, 11, 31)).format('YYYY-MM-DD')}
                  startDate={selectedDates.startDate}
                  endDate={selectedDates.endDate}
                  setQuery={setQuery} query={query}
                  gridlevel={gridlevel}
                ></DataCard></div> }

                { 
                (selectedTab == 2) &&
                <div> <DataCard setRowData={setRowData} dashboardData={empaneledTestingData} type={"empanel"} params={params} scrollToTable={scrollToTable} dashboardType={dashboardType} masterCardClicked={masterCardClicked} onCardClick={(id, cName)=>{onCardClick(id, cName, true)}} setCategoryId={setCategoryId} setDashboardType={'empanel'} selectedTab={selectedTab}
                  // formattedfromDate={selectedYear !== 'All' ? moment(new Date(selectedYear, 0, 1)).format('YYYY-MM-DD') : moment(new Date(startYear, 0, 1)).format('YYYY-MM-DD')}
                  // formattedtoDate={selectedYear !== 'All' ? moment(new Date(selectedYear, 11, 31)).format('YYYY-MM-DD') : moment(new Date(currentYear, 11, 31)).format('YYYY-MM-DD')}
                  startDate={selectedDates.startDate}
                  endDate={selectedDates.endDate}
                  setQuery={setQuery} query={query}
                  gridlevel={gridlevel}
                ></DataCard></div>}

                </div>
                <div style={{width: '100%', padding: '20px 20px 0px 30px', margin: '5px'}}>
                <div style={{display: 'flex', flexDirection: 'row', alignItems:'center', justifyContent: 'center'}}>

                  <div style={{width: '65%', marginBottom: '10px'}}>
                  <DataCard
                    setRowData={setRowData} 
                    selectedTab={selectedTab}
                    dashboardData={warehouseData}
                    type={"warehouse"}
                    params={params} 
                    dashboardType={dashboardType} 
                    masterCardClicked={masterCardClicked} 
                    onCardClick={(id, cName)=>{onCardClick(id, cName, false)}} 
                    setCategoryId={setCategoryId} 
                    setDashboardType={setDashboardType}
                    // formattedfromDate = {formattedfromDate}
                    // formattedtoDate = {formattedtoDate}
                    startDate={selectedDates.startDate}
                    endDate={selectedDates.endDate}
                    gridlevel={gridlevel}
                  >

                  </DataCard>
                  </div>
                </div>
                </div>
                </div>
                </div>

                <div style={{display:'flex', justifyContent: 'flex-end', margin: '10px 20px 10px 10px', padding:'5px'}} hidden={isReportingGridLinkHidden}>
                  <span>
                    <a href="javascript:void(0)" onClick={()=>navigate(`/reporting/${params.categoryname.toLowerCase()}/${params.categoryId}/agency?level=state&geoId=${queryParams.get('geoId')}&view=${selectedTab}`)}>List of {stateName} Agencies</a>
                  </span>
                </div>

                <div className={ gridlevel.level !== 'district' && 'master-data-block'} style={{display: 'flex', paddingLeft: "40px", justifyContent:"space-between", paddingBottom: "30px", paddingRight: "30px", minWidth:"100%"}}>
                    <Card className={gridlevel.level !== 'district' ? 'flex-basis-25' : 'flex-1'} sx={{ boxShadow: "0px 0px 15px 0px rgb(155 150 150 / 89%)"}}>
                        <CardHeader sx={{borderBottom: '1px solid #80808038', padding: '10px 20px'}} title="MASTER DATA" titleTypographyProps={{fontSize: '14px', color: 'grey' }}>
                            
                        </CardHeader>
                        <CardContent className={gridlevel.level == 'district' && 'd-flex justify-content-around'} sx={{padding: "20px"}}>
                        {sideCardsData.map((data)=>(
                            <Card className={`${data.className} ${data.clicked ? 'active' :''} flex-basis-20`} sx={{marginBottom: "20px"}} onClick={() => handleClick(data.categoryId, data.name, data.clicked)}>
                                <CardContent sx={{fontSize: "13px"}}>
                                    
                                    <div>
                                        <b>{data.count}</b>
                                    </div>
                                    <div>
                                        {data.name.replace('MILLER',MILLER_UI_TEXT)}
                                    </div>
                                </CardContent>
                            </Card>
                        ))}

                        </CardContent>
                    </Card>
                    {gridlevel.level !== 'district' &&  <Card id="map-card" ref={mapRef} sx={{flexBasis: 'calc(75% - 20px)', padding: "20px", boxShadow: "0px 0px 15px 0px rgb(155 150 150 / 89%)"}}>
                        {memoizedMapComponent}
                   
                    </Card>}
                    
        
                </div>

                {(["agency-targetManufacturers", "agency-sourceManufacturers", "agency-lotList", "agency-batchesList"].includes(query['type'])) && !hideAgencyDrilldowns
          // query["type"] != "agency-all" && query["type"] != "district-all" && query['type'] != 'warehouse-all' && query['type'] != 'labs-all' 
          &&(
           
            <a
              style={{
                color: 'rgba(13, 110, 253, 1)',
                cursor: "pointer",
                fontSize: "1.2rem",
                marginLeft: '10px'
              }}
              onClick={handleBackClick}
            >
              <ArrowBackIcon />
              <u>back</u>
            </a>
          )}

          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px', padding: '10px 10px 5px 10px' }}><Typography style={{ fontWeight: "400", fontFamily: "outfit" }}></Typography>{!hideAgencyDrilldowns && heading}</div>


                { (query['type']=='agency-all' || query['type']=='agency-targetManufacturers' || query['type'] === 'agency-sourceManufacturers') && !hideAgencyDrilldowns &&
                 ( 
                  
                  <div className="ag-theme-alpine" 
                  style={{ 
                    // width: '100%', 
                  height:'500px', padding: '0px 20px 0px 20px' }}
                  ref={tableRef}
                  >
                    {/* <GridItem
                    rowData={rowData}
                    columnDefs={columnDefs}
                    animateRows={true}
                    getRowStyle={getRowStyle}
                    // onRowClicked={props.onRowClick}
                    // onCellClicked={props.onCellClicked}
                    defaultColDef={defaultColDef}
                    tooltipShowDelay={0}
                    tooltipHideDelay={2000}
                    enableBrowserTooltips={true}
                    gridOptions={gridOptions}
                    /> */}
                    <MapPageAgencyGrid 
                      rowData={rowData}
                      onRowClick={handleRowClick}
                      query={query}
                      searchKeyWord={searchKeyWord}
                      setSearchKeyWord={setSearchKeyWord}
                      onCellClicked={onCellClicked}
                      heading={heading}
                    />
                  </div>
                
                 )   
                 }

                  { ['agency-lotList', 'agency-batchesList'].includes(query['type']) && !hideAgencyDrilldowns &&
                 ( 
                  
                  <PremixGrid
                  query={query}
                  showBatchesOrLotsForMonitor={true}
                  isBatchList={query['type'].includes('batchesList')}
                  isLotList={query['type'].includes('lotList')}
                />
                
                 )   
                 }

            </Box>

            
        </div>
    )
}

export default DashboardPage
import React, { useEffect } from "react";
import DatePicker from "react-datepicker";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import moment from "moment";
import useTranslatewrapper from "../../../components/useTranslateWrapper";

function DatePickerComponent({
  populateDate,
  schema,
  setGetDate,
  getDate,
  disableVendorData,
  setBatchDate,
  setShowButton,
}) {
  const {translate} = useTranslatewrapper()

  const [value, setValue] = React.useState(null);



  const handleChange = (newValue) => {
    setValue(newValue);
    setShowButton(true);

    if(newValue == null){
      setBatchDate({
      title: schema?.schema?.title,
      date: null
      })

      getDate.push({
        title: schema?.schema?.title,
        date: null
      })
      return;
    }

    getDate.push({
      title: schema?.schema?.title,
      date: `${newValue.$y}-${newValue.$M < 9 ? 0 : ""}${newValue.$M + 1}-${
        newValue.$D <= 9 ? 0 : ""
      }${newValue.$D}`,
    }); 
    setBatchDate({
      title: schema?.schema?.title,
      date: `${newValue.$y}-${newValue.$M < 9 ? 0 : ""}${newValue.$M + 1}-${
        newValue.$D <= 9 ? 0 : ""
      }${newValue.$D}`,
    });

    // if(schema?.schema?.title === "Manufacturing Date" ){
    //   setGetDate({ManufacturingDate:moment(newValue)?.format("YYYY-MM-DD")})

    // }
    // else if (schema?.schema?.title === "Expiry Date"  ) {
    //   setGetDate({ExpiryDate:moment(newValue)?.format("YYYY-MM-DD")})
    // }
  };
useEffect(()=>{
  if ( schema?.schema?.title === translate('manufacturingDate') ){
    setValue(Date.now())
    
    getDate.push({
      title: schema?.schema?.title,
      date: moment(Date.now()).format("YYYY-MM-DD"),
    })
    setBatchDate({
      title: schema?.schema?.title,
      date: Date.now(),
    })
   }
  
},[])
  useEffect(() => {
    if (populateDate) {
      if (
        schema?.schema?.title === translate('manufacturingDate') &&
        populateDate?.ManufacturingDate
      ) {
        setValue(populateDate?.ManufacturingDate);
        populateDate &&
          setBatchDate({
            title: schema?.schema?.title,
            date: populateDate?.ManufacturingDate,
          });
        // setGetDate((getDate ||[]).push({
        //   title: schema?.schema?.title,
        //   date: populateDate?.ManufacturingDate,
        // }));
        getDate.push({
          title: schema?.schema?.title,
          date: populateDate?.ManufacturingDate,
        })

      } else if (
        schema?.schema?.title === translate('expiryDate') &&
        populateDate?.ExpiryDate
      ) {
        setValue(populateDate?.ExpiryDate);
        setBatchDate({
          title: schema?.schema?.title,
          date: populateDate?.ExpiryDate,
        });

        // setGetDate((getDate ||[]).push({
        //   title: schema?.schema?.title,
        //   date: populateDate?.ExpiryDate,
        // }));
        getDate.push({
          title: schema?.schema?.title,
          date: populateDate?.ExpiryDate,
        })
        // debugger;
        
      }
      else if (
        schema?.schema?.title === "Date Of Dispatch" &&
        populateDate?.DispatchDate
      ) {
        setValue(populateDate?.DispatchDate);
        setBatchDate({
          title: schema?.schema?.title,
          date: populateDate?.DispatchDate,
        });
        getDate.push({
          title: schema?.schema?.title,
          date: populateDate?.DispatchDate,
        })
        }
    }
   
  }, [populateDate,schema.schema.title]);

  const minDate = ()=>{
    if(schema?.schema?.title === translate('manufacturingDate')){
      return moment().subtract(2, 'years');
    }
    if(schema?.schema?.title === translate('expiryDate')){
      return moment();
    }
    return null;
  }

  const maxDate = ()=>{
    if(schema?.schema?.title === translate('manufacturingDate')){
      return moment();
    }
    if(schema?.schema?.title === translate('expiryDate')){
      return null
    }
  }

  return (
    <div sx={{ width: "30%" }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <MobileDatePicker
          disabled={disableVendorData}
          label={`${schema?.schema?.title} ${schema?.schema?.title === translate('expiryDate') ? "" : "*"} `}
          inputFormat="DD/MM/YYYY"
          value={value}
          maxDate={maxDate()}
          minDate={minDate()}
          onChange={handleChange}
          renderInput={(params) => <TextField {...params} inputProps={{...params.inputProps, readOnly: true}} />}
          
          required
        />
      </LocalizationProvider>
    </div>
  );
}
// minDate={schema?.schema?.title === "Expiry Date" ? new Date((getDate.filter((data) => data.title === "Manufacturing Date").reverse()[0]?.date)) || Date.now() : null}


export default DatePickerComponent;

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { MAIN_BLUE } from "../../../color";
import validator from "@rjsf/validator-ajv8";
import { LOT_TEST_DETAIL, VENDOR_DETAIL } from "../../../textConstant";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Form } from "@rjsf/mui";
import VendorLicenseDetails from "../VendorDetails/VendorLicenseDetails";
import GetAllFiles from "../GetAllFiles/GetAllFiles";
import DatePickerComponent from "../DatePicker/DatePickerComponent";
import {
  createPremixBatchByFrkUser,
} from "../../../services/FortificationService";
import moment from "moment";
import KeycloakService from "../../../services/KeycloakService";
import ManufacturerDropdown from "../../Admin/labService/Users/Manufacturer Dropdown/ManufacturerDropdown";
import { useAppDispatch, useAppSelector } from "../../../services/StoreHooks";
import { useNavigate } from "react-router-dom";
import useTranslatewrapper from "../../../components/useTranslateWrapper";

function PremixCreate() {
  const { translate } = useTranslatewrapper();
  const categoryNameToIdSelectorRedux = useAppSelector((state) =>
    state?.categoriesData?.categories
  );

  const categoryNameToIdMapRedux = useMemo(()=>{
    return (
      categoryNameToIdSelectorRedux.reduce(
      (acc, current) => {
        acc[current.name] = current.id.toString();
        return acc;
      },
      {}
    ))
  }, [categoryNameToIdSelectorRedux])

  const [isExpanded1, setIsExpanded1] = useState(true);
  const [isExpanded2, setIsExpanded2] = useState(true);
  const [isExpanded3, setIsExpanded3] = useState(true);
  // const [isExpanded4, setIsExpanded4] = useState(true);
  const [openSearchByLicenseNumber, setOpenSearchByLicenseNumber] =
    useState(false);
  const vendorDetailsUiSchema = {
    accreditedByAgency: {
      "ui:classNames": "accredited",
    },

    completeAddress: {
      "ui:widget": "textarea",
    },
    vendorType: {
      "ui:widget": "radio",
      "ui:classNames": "radio-button custom-radio-button",
    },
  };

  const [batchMfgDate, setBatchMfgDate] = useState("");
  const [batchExpDate, setBatchExpDate] = useState("");
  const [sampleTestingDate, setSampleTestingDate] = useState("");
  const [getDate, setGetDate] = useState([]);
  const [VendorData, setVendorData] = useState({});
  const [tempVendorData, setTempVendorData] = useState({})
  const [testDetailsData, setTestDetailsData] = useState({});
  const [checked, setChecked] = useState(false);
  // const [vendorList, setVendorList] = useState([]);
  const [batchFormData, setBatchFormData] = useState({
    ManufacturingDate: moment().local().format("yyyy-MM-DD"),
  });
  const [fileBody, setFileBody] = React.useState([]);
  const [deleteFiles, setDeleteFiles] = React.useState([]);
  const [createFileUploadFields, setCreateFileUploadFields] = useState([]);
  const [showButton, setShowButton] = useState(true);
  const [removedNames, setRemovedNames] = useState([]);
  const [manufacturerData, setManufacturerData] = useState();

  const navigate = useNavigate();

  const vendorDetailsSchemastate = {
    properties: {
      name: {
        type: "string",
        title: translate('nameOfVendor'),
      },
      vendorType: {
        title: translate('vendorType'),
        enum: ["Manufacturer", "Trader", "Broker"],
        enumNames: [
          translate("manufacturer"),
          translate("trader"),
          translate("broker"),
        ],
      },
      licenseNumber: {
        type: "string",
        title: translate('fssaiLicenseNo'),
      },
      completeAddress: {
        type: "string",
        title: translate('address'),
      },
      accreditedByAgency: {
        type: "boolean",
        default: false,
        title: translate('manufAccreditedByAgency'),
      },
    },
    required: ["licenseNumber", "name"],
  };

  const userDetails = KeycloakService.getUserDetail();

  const batchDetailsSchema = {
    properties: {
      BatchName: {
        type: "string",
        title: translate('name'),
      },

      UserDefinedBatchNo: {
        type: "string",
        title: translate('manufacturerBatchNo'),
      },

      ManufacturingDate: {
        type: "string",
        title: translate('manufacturingDate'),
        readOnly: true,
      },

      ExpiryDate: {
        title: translate('expiryDate'),
        type: "string",
      },
      PremixPercentageNeededForFRK: {
        type: "number",
        title: translate('percentInFrk'),
        // readOnly: true,
        // default: 2,
      },
      TotalQuantity: {
        title: translate('totalQuantityOfPremixProcurement'),
        type: "number",
        minimum: 0.01,
      },
    },
    required: ["BatchName",
      //  "PremixPercentageNeededForFRK", 
       "TotalQuantity"],
  };

  const testDetailsSchema = {
    properties: {
      sampleTestingDate: {
        title: "Sample Testing Date",
        type: "string",
      },

      reportNo: {
        title: translate('reportNo'),
        type: "string",
      },
      GetAllFiles: {
        title: "Upload File",
        type: "string",
      },
    },
    required: ["sampleTestingDate", "reportNo", "getAllFiles"],
  };

  const batchDetailsUiSchema = {
    ManufacturingDate: {
      "ui:classNames": "date custom-date-field",
      "ui:widget": "GetDateWidget",
    },
    ExpiryDate: {
      "ui:classNames": "date custom-date-field",
      "ui:widget": "GetDateWidget",
      "ui:showError": false,
    },
  };

  const testDetailsUiSchema = {
    sampleTestingDate: {
      "ui:classNames": "date custom-date-field",
      "ui:widget": "GetDateWidget",
      "ui:showError": false,
    },
    GetAllFiles: {
      "ui:classNames": "component getallfiles",
      "ui:widget": "GetAllFilesWidget",
    },
  };

  const setBatchDate = (data) => {
    if (data.title === translate('manufacturingDate')) {
      setBatchMfgDate(data.date);
    }
    if (data.title === translate('expiryDate')) {
      setBatchExpDate(data.date);
    }
    if (data.title === "Sample Testing Date") {
      setSampleTestingDate(data.date);
    }
  };

  const GetAllFilesWidget = () => {
    return (
      <GetAllFiles
        getId={{
          categoryId: categoryNameToIdMapRedux["PREMIX"],
          type: "batch",
        }}
        createFileUploadFields={createFileUploadFields}
        getFileData={setFileBody}
        handleFileDelete={setDeleteFiles}
        setRemovedNames={setRemovedNames}
        setShowButton={setShowButton}
        disableVendorData={false}
        isPremixAddFromFrk={true}
      />
    );
  };

  const GetDateWidget = (batchDetailsSchema) => {
    return (
      <DatePickerComponent
        schema={batchDetailsSchema}
        setGetDate={setGetDate}
        setBatchDate={setBatchDate}
        getDate={getDate}
        disableVendorData={false}
        setShowButton={() => {}}
      />
    );
  };

  const removeDeletedFiles = () => {
    if (!fileBody) return [];
    let tempFileBody = [
      ...fileBody.filter((obj) => !(obj.name == "" && obj.categoryDocId == "")),
    ];
    removedNames.forEach((removedName) => {
      const deleteFileIndexesArray = fileBody.filter(
        (file) => !file?.id && file.name === removedName
      );
      deleteFileIndexesArray.forEach((deleteItem) => {
        const deleteFileIndex = tempFileBody.findIndex(
          (file) => file.name === deleteItem.name
        );
        if (deleteFileIndex !== -1) {
          tempFileBody.splice(deleteFileIndex, 1);
        }
      });
    });

    deleteFiles.forEach((deleteItem) => {
      const deleteFileIndex = tempFileBody.findIndex(
        (file) =>
          file.path === deleteItem.path &&
          file.name === deleteItem.name &&
          file.categoryDocId === deleteItem.categoryDocId
      );
      if (deleteFileIndex !== -1) {
        tempFileBody.splice(deleteFileIndex, 1);
      }
    });

    return tempFileBody;
  };

  const testDetailsWidgets = {
    GetAllFilesWidget,
    GetDateWidget,
  };

  const batchDetailsWidgets = {
    GetDateWidget,
  };

  useEffect(() => {

  }, []);

  // const handleVendorData = (data) => {
  //   setVendorData(data);
  // };

  // const fetchVendorList = async () => {
  //   let res = await getPremixManufacturersList('2','MANUFACTURER')

  //   try {
  //     setVendorList(res.data.data);
  //   } catch (error) {}
  // };

  const handleSubmit = async () => {
    if (validateFunction()) {
      const payload = {
        name: VendorData?.name,
        licenseNumber: VendorData?.licenseNumber,
        vendorType: VendorData?.vendorType,
        vendorAddress: VendorData?.address,
        vendorId: VendorData?.id,
        batchName: batchFormData?.BatchName,
        manufacturingDate: getDate
          ?.filter((date) => date.title === translate('manufacturingDate'))
          .reverse()[0]?.date,
        expiryDate: getDate
          ?.filter((date) => date.title === translate('expiryDate'))
          .reverse()[0]?.date,
        categoryId: parseInt(categoryNameToIdMapRedux['PREMIX']),
        totalQuantity: batchFormData?.TotalQuantity,
        manufacturerBatchNumber: batchFormData?.UserDefinedBatchNo,
        reportNumber: testDetailsData?.reportNo,
        labId: manufacturerData,
        sampleTestDate: getDate
          ?.filter((date) => date.title === "Sample Testing Date")
          .reverse()[0]?.date,
        batchDocRequestDtos: removeDeletedFiles().map(item=>({...item,categoryDocId : ''})),
        createdBy: userDetails.firstName+" "+ userDetails.lastName,
        sizeUnits: [{
          quantity: 1,
          size: batchFormData?.TotalQuantity,
          uomId: 1
        }],
        batchProperties: [
          {
            name: "manufacture_name",
            value: `${VendorData.name}`,
            id: null,
          },
          {
            name: "manufacturer_id",
            value: `${VendorData.id}`,
            id: null,
          },
          {
            name: "manufacture_type",
            value: `${VendorData.vendorType}`,
            id: null,
          },
          {
            name: "manufacture_licenseNumber",
            value: `${VendorData.licenseNumber}`,
            id: null,
          },
          {
            name: "manufacture_accreditedByAgency",
            value: `${VendorData.accreditedByAgency}` || false,
            id: null,
          },
          {
            name: "manufacture_batchNumber",
            value: `${batchFormData?.UserDefinedBatchNo || ""}`,
            id: null,
          },
          {
            name: "batch_name",
            value: `${batchFormData?.BatchName}`,
            id: null,
          },
          {
            name: "percentage_of_premix_required",
            value: `${batchFormData?.PremixPercentageNeededForFRK}`,
            id: null
          },
          {
            name: "manufacture_completeAddress",
            value: `${VendorData.completeAddress}`,
            id: null,
          }

        ],
      };

      let res = await createPremixBatchByFrkUser(payload, categoryNameToIdMapRedux);
      if(res?.status == 201 || res?.status == 200){
        dispatchNotification("success", [translate('premix') + " " + translate('batchHasBeenCreated')]);
        navigate(`/premix/${categoryNameToIdMapRedux['PREMIX']}/lot`)
        return
      }
      // console.log(res, "res");
    }
    // console.log(VendorData, "vendorData");
    // console.log(batchFormData, "batchData");
    // console.log(testDetailsData, "testingData");
    // console.log(
    //   getDate
    //     ?.filter((date) => date.title === "Manufacturing Date")
    //     .reverse()[0]?.date,
    //   "manuf date"
    // );
    // console.log(
    //   getDate?.filter((date) => date.title === "Expiry Date").reverse()[0]
    //     ?.date,
    //   "exp date"
    // );
    // console.log(
    //   getDate
    //     ?.filter((date) => date.title === "Sample Testing Date")
    //     .reverse()[0]?.date,
    //   "Sample Testing Date from arr"
    // );

    // console.log(batchMfgDate, "batchmfgdate");
    // console.log(batchExpDate, "batchexpdate");
    // console.log(sampleTestingDate, "sampleTestingDate");

    // console.log(manufacturerData, "manufacturerData");
    // // console.log(fileBody, 'fileBody')
    // console.log(removeDeletedFiles(), 'removedeletedfiles')
  };



  const dispatch = useAppDispatch();

  const dispatchNotification = (typ, msg) => {
    dispatch({
      type: "SHOW_NOTIFICATION",
      data: {
        open: true,
        type: typ,
        message: msg,
      },
    });
  };

  const validateFunction = () => {
    let arrErr = [];

    if (!VendorData.id) {
      arrErr.push("vendor is not selected.");
    }
    if (
      !batchFormData.BatchName ||
      !batchFormData.TotalQuantity 
      // || !batchFormData.PremixPercentageNeededForFRK
    ) {
      arrErr.push("please enter all required batch details");
    }

    if (!batchMfgDate) {
      arrErr.push("please enter manufacturing date");
    }
    // if (!batchExpDate) {
    //   arrErr.push("please enter expiry date");
    // }
    // if (!sampleTestingDate) {
    //   arrErr.push("please enter sample testing date");
    // }

    // if (!testDetailsData.reportNo) {
    //   arrErr.push("please enter test report number");
    // }

    const uploadedFiles = removeDeletedFiles();

    if (!uploadedFiles.some((ele) => !!ele.categoryDocId)) {
      arrErr.push("please upload the Test Report");
    }

    if (!manufacturerData) {
      arrErr.push("please select lab");
    }

    let msg = "";
    arrErr.map((err) => (msg += err + "\n"));

    if (arrErr && arrErr.length > 0) {
      dispatchNotification("error", arrErr);
      return false;
    } else {
      return true;
    }
  };

  const declarationStr = translate('premixSelfAddByFrkDeclaration').replace("USERNAME", `<strong>${userDetails.firstName} ${userDetails.lastName}</strong>`).replace('MANUFACTURERNAME', `<strong>${userDetails.manufacturerName}</strong>`)

  return (
    <div className="main-content-overlay">
      <Box className="main-content-div" style={{ position: "relative" }}>
        <Typography
          align="center"
          mt={2}
          variant="h6"
          sx={{ color: MAIN_BLUE, fontWeight: "600" }}
        >
          {translate('selfDeclarationForPremix')}
        </Typography>

        <div className="m-3">
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checked}
                  onChange={(event) => setChecked(event.target.checked)}
                  name="declarationCheckbox"
                />
              }
              label={
                <div style={{ display: "inline-block", fontSize: "larger" }} dangerouslySetInnerHTML={{ __html: declarationStr }}>
                  {/* I,{" "}
                  <strong>
                    {userDetails.firstName} {userDetails.lastName}
                  </strong>
                  , representing <strong>{userDetails.manufacturerName}</strong>
                  , hereby declare that in the absence of data entry by the
                  manufacturer of Premix, will enter the manufacturing and
                  laboratory testing data for Premix batch. */}
                </div>
              }
            />
          </FormGroup>
        </div>

        <Accordion
          onChange={(e, expanded) => {
            setIsExpanded1(expanded);
          }}
          defaultExpanded={true}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon
                sx={{ color: "#fff", background: "#0075FF", height: "35px" }}
              />
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Box className="formTitle">
              <Typography className="formBox-text"> {translate('vendorDetails')}</Typography>
              {isExpanded1 ? (
                <Typography className="formBox-helperText">
                  {translate('clickToCollapse')}
                </Typography>
              ) : (
                <Typography className="formBox-helperText">
                  {translate('clickToExpand')}
                </Typography>
              )}
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            {
              <Box id="VendorDetails" className="form-div ">
                <VendorLicenseDetails
                  open={openSearchByLicenseNumber}
                  setOpenSearchByLicenseNumber={setOpenSearchByLicenseNumber}
                  categoryId={parseInt(categoryNameToIdMapRedux['PREMIX'])}
                  handleClickOk={() => {
                    setVendorData(tempVendorData)
                  }}
                  fetchVendorList={() => {}}
                  setVendorData={setVendorData}
                  setTempVendorData={setTempVendorData}
                  vendorData={VendorData}
                  isPremixSelfAdd={true}
                  vendorList={[]}
                  setVendorList={()=>{}}
                ></VendorLicenseDetails>
                <Box p={2} id="rjsf">
                  <Form
                    schema={vendorDetailsSchemastate}
                    validator={validator}
                    disabled={true}
                    uiSchema={vendorDetailsUiSchema}
                    children={true}
                    formData={VendorData}
                    className="vendor-detail-styling"
                    onChange={(e) => {
                      // console.log(VendorData, "vendorData");
                      // setVendorData(e.formData);
                    }}
                  ></Form>
                  <Button
                    onClick={(e) => {
                      setOpenSearchByLicenseNumber(true);
                    }}
                  >
                    {translate('vendorWithFssaiLicenseNo')}
                  </Button>
                </Box>
              </Box>
            }





            {/* <VendorDetails
              VendorData={VendorData}
              handleData={handleVendorData}
              disableVendorData={false}
              independentBatch={true}
              setShowButton={() => {}}
              vendorList={vendorList}
              categoryId={2}
              setVendorList={setVendorList}
              fetchVendorList={fetchVendorList}
              isInspector={false}
              hideAddVendorButton={true}
              isPremixSelfAdd={true}
            /> */}
          </AccordionDetails>
        </Accordion>

        <Accordion
          onChange={(e, expanded) => {
            setIsExpanded2(expanded);
          }}
          defaultExpanded={true}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon
                sx={{ color: "#fff", background: "#0075FF", height: "35px" }}
              />
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Box className="formTitle">
              <Typography className="formBox-text">
                {translate('batchDetails')}
              </Typography>
              {isExpanded2 ? (
                <Typography className="formBox-helperText">
                  {translate('clickToCollapse')}
                </Typography>
              ) : (
                <Typography className="formBox-helperText">
                  {translate('clickToExpand')}
                </Typography>
              )}
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Box id="VendorDetails" className="form-div ">
              <Box sx={{ padding: "0 16px" }} id="rjsf">
                <div id="batch-detail-form">
                  {
                    <Form
                      schema={batchDetailsSchema}
                      validator={validator}
                      uiSchema={batchDetailsUiSchema}
                      formData={batchFormData}
                      children={true}
                      widgets={batchDetailsWidgets}
                      className="batch-form-styling"
                      onChange={(e) => {
                        e.formData.BatchName = !e.formData.BatchName?.trim()
                          ? ""
                          : e.formData.BatchName;
                        setBatchFormData(e.formData);
                      }}
                    ></Form>
                  }
                </div>
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>

        <Accordion
          onChange={(e, expanded) => {
            setIsExpanded3(expanded);
          }}
          defaultExpanded={true}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon
                sx={{ color: "#fff", background: "#0075FF", height: "35px" }}
              />
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Box className="formTitle">
              <Typography className="formBox-text">{translate('testDetails')}</Typography>
              {isExpanded3 ? (
                <Typography className="formBox-helperText">
                  {translate('clickToCollapse')}
                </Typography>
              ) : (
                <Typography className="formBox-helperText">
                  {translate('clickToExpand')}
                </Typography>
              )}
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            {
              <Box id="VendorDetails" className="form-div ">

                <Box p={2} id="rjsf">
                <div style={{ width: "35%", marginTop: '20px' }}>
                  <ManufacturerDropdown
                    setManufacturerData={setManufacturerData}
                    // setManufacturerName={setManufacturerName}
                    disableDropdown={false}
                    isActiveLab={true}
                    categoryId={parseInt(categoryNameToIdMapRedux['PREMIX'])}
                    manufacturerId={userDetails.id}
                  />
                </div>
                  <Form
                    schema={testDetailsSchema}
                    validator={validator}
                    uiSchema={testDetailsUiSchema}
                    children={true}
                    formData={testDetailsData}
                    widgets={testDetailsWidgets}
                    className="vendor-detail-styling"
                    onChange={(e) => {
                      setTestDetailsData(e.formData);
                    }}
                  ></Form>
                </Box>
              </Box>
            }
          </AccordionDetails>
        </Accordion>

        <div className="save-button-div d-flex justify-content-center mb-4">
          <Button
            variant="contained"
            disabled={!checked}
            onClick={handleSubmit}
          >
            {translate('submit')}
          </Button>
        </div>
      </Box>
    </div>
  );
}

export default PremixCreate;

import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import "../../App.css";
import { Button, CircularProgress, Dialog, Grid, Tooltip, Typography } from "@mui/material";
import { useAppSelector, useAppDispatch } from "../../services/StoreHooks";
import { useNavigate, useParams } from "react-router-dom";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import {
  LISTING_BATCHES_HEADER,
} from "./ColumnHeaderBatches";
import "./../GridItem/AgGrid.css";
import GridItem from "../GridItem/GridItem";
import moment from "moment";
import { LIGHT_GREY, MAIN_BLUE } from "../../color";
import { PAGE_SIZE_ARRAY } from "../../constants";
import DeletableChips from "../../components/Chips/DeletableChip";
import SimpleDialogDemo from "../../components/Filter/FilterPopup";
import {
  getStateList,
} from "../../services/FortificationService";
import FilterButton from "../../components/CommonFilterCreateButton/FilterButton";
import SearchBar from "../../components/CommonSearchBar/Search";
import {
  INVENTORY_TEXT,
} from "../../textConstant";
import { LISTING_LOTS_HEADER } from "./ColumnHeaderLots";
import {
  getAllInventoryBatches,
} from "../../services/InventoryService";
import { getMenuDetails } from "../../modules/menuSlice";
import DispatchLotComponent from "../../components/DispatchLot/DispatchLotComponent";
import KeycloakService from "../../services/KeycloakService";
import useTranslatewrapper from "../../components/useTranslateWrapper";
const InventoryPage = (props) => {
  const {translate} = useTranslatewrapper()
  const menuDetails = useAppSelector(getMenuDetails);
  const [loader, setLoader] = React.useState(false);
  const [gridApi, setGridApi] = useState(null);
  const [columnDefsBatches, setColumnDefsBatches] = React.useState(
    LISTING_BATCHES_HEADER(translate)
  );
  const gridRef = useRef();
  const [rowbatchData, setRowbatchData] = useState([]);
  const [pageSize, setPageSize] = React.useState(12);
  const [pageNo, setPageNo] = React.useState(1);
  const [searchValue, setSearchValue] = React.useState("");
  const [selected, setSelected] = React.useState([]);
  const [stateId, setStateId] = React.useState([]);
  const [clear, setclear] = useState(false);
  const RowSelectionType = "single";
  const [chip, setChip] = useState("");
  const [options, setOptions] = React.useState([]);
  const [activeFilters, setActiveFilters] = React.useState({});
  const filterDataRedux = useAppSelector((state) => state.filterData);
  const [filterData, setFilterData] = React.useState({
    state: [],
    expStartDate: "",
    expEndDate: "",
    mnmfEndDate: "",
    mnfStartDate: "",
  });
  const [filterStates, setFilterStates] = React.useState([]);
  const [chipsState, setChipsState] = React.useState({});
  const [count, setCount] = React.useState(1);
  const [openFilter, setOpenFilter] = React.useState(false);
  const params = useParams();
  let chipMap = {};
  const heading = translate(params?.type.replace('miller', 'miller(FR)'))
  const handleClickOpen = () => {
    setOpenFilter(true);
  };
  const handleClose = (value) => {
    setOpenFilter(false);
  };
  const isWarehouseProcured = JSON.parse(sessionStorage.getItem('user'))?.isWarehouseProcured
  const isNftb = JSON.parse(sessionStorage.getItem('user'))?.role.includes("NFTB")
  const showDispatchButtonForNftb = isNftb ? (params.type==='frk-lots') : true 
  const showDispatch = (params.type==='miller-lots' || params.type==='premix-lots' || params.type==='frk-lots')

  const handleCloseDispatch = (fetchData = false) => {
    setOpenDispatch(false);
    if(fetchData){

      setLoader(true);
      fetchStateList();
      updateChips();
      fetchBatchData(pageNo, pageSize)
    }
  };
  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 170,
      maxWidth: 250,
      floatingFilter: false,
      enableRowGroup: true,
      enablePivot: true,
      enableValue: true,
      resizable: true,
    };
  }, []);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const handleSelectChange = (values) => {
    setSelected(values);
  };

  useEffect(() => {
    //debugger;
    setLoader(true);
    fetchStateList();
    updateChips();
    fetchBatchData(pageNo, pageSize);

  }, [filterDataRedux]);

  useEffect(()=>{
//call api to get target manufacturer categories
  }, [])

  const dateValue = (date) => {
    if (date) {
      return moment.utc(date).local().format("yyyy/MM/DD");
    } else {
      return "";
    }
  };
  const setPage = (page) => {
    setPageNo(page);
    fetchBatchData(page, pageSize);
  };
  const counter = () => {
    return count % pageSize === 0
      ? Math.floor(count / pageSize)
      : Math.floor(count / pageSize) + 1;
  };
  const setFilters = (filterData) => {
    // debugger
    var json = filterDataRedux.searchCriteriaList;
    var json1 = structuredClone(json);
    if (filterStates.length != 0) {
      setFilterData({ ...filterData, state: filterStates });
      json1[3].value = filterStates;
    } else {
      json1[3].value = [null];
    }
    json1[0].value = searchValue;
    json1[1].value = `${dateValue(filterData.mnfStartDate)}${"-"}${dateValue(
      filterData.mnfEndDate
    )}`;
    json1[2].value = `${dateValue(filterData.expStartDate)}${"-"}${dateValue(
      filterData.expEndDate
    )}`;
    dispatch({
      type: "UPDATE_FILTER",
      data: json1,
    });
  };
  //to call inventory api with lot (if the state has only lot) or batch
  const fetchLotOrBatch = ()=>{
    const menuItem = menuDetails.find((elem) => elem.categoryId == params.categoryId)
    let onlyBatch = false
    if (menuItem) {
      onlyBatch = params.type.includes("batch")
    }
    onlyBatch ? setColumnDefsBatches(LISTING_BATCHES_HEADER(translate)) : setColumnDefsBatches(LISTING_LOTS_HEADER(translate))
    return onlyBatch ? "batch" : "lot"
  }
  const fetchBatchData = async (pageNo, pageSize) => {
      const batchData = await getAllInventoryBatches(
      params.categoryId,
      fetchLotOrBatch(),
      filterDataRedux,
      pageNo,
      pageSize
    );
    if (batchData?.data?.data) {
      setCount(batchData?.data?.count);
      setRowbatchData(batchData?.data?.data);
    }
    setLoader(false);
  };
  const fetchStateList = async () => {
    const stateList = await getStateList(fetchLotOrBatch().toUpperCase());
    const getList = stateList.data.data.map((list) => {
      return {
        value: list.name,
        label: list.displayName,
        id: list.id,
      };
    });
    const getListId = stateList.data.data.map((list) => {
      return {
        id: list.id,
      };
    });
    setOptions(getList);
    setStateId(getListId);
  };
  function onGridReady(params) {
    setGridApi(params.api);
  }
  const onRowClicked = useCallback(() => {
    const selectedRows = gridRef.current.api.getSelectedRows();
    let splitSeperator  ;
    if(params.type.includes('batches')){
      splitSeperator = '-batches'
    }else if(params.type.includes('lots')){
      splitSeperator = '-lots'
    }
    const category =splitSeperator ?  params?.type?.split(splitSeperator)[0] : params?.type;
    navigate(
      `/${category}/${params.categoryId}/${selectedRows[0]?.batchNo ? "batch" : "lot"
      }/details/${selectedRows[0].id}`
    );
  }, [params]);

  const setFiltersSearch = (data) => {
    var json = filterDataRedux.searchCriteriaList;
    var json1 = structuredClone(json);

    if (data == null) {
      json1[0].value = "";
    } else {
      json1[0].value = data.label;
      setChip(data.label);
    }
    dispatch({
      type: "UPDATE_FILTER",
      data: json1,
    });
  };

  const removeSearch = (data) => {
    var json = filterDataRedux.searchCriteriaList;
    var json1 = structuredClone(json);
    if (data != null) {
      json1[0].value = "";
      setChip("");
      setclear(true);
    }

    dispatch({
      type: "UPDATE_FILTER",
      data: json1,
    });
  };
  const updateChips = () => {
    filterDataRedux.searchCriteriaList.map((entry) => {
      switch (entry.key) {
        case "batchNo":
          if (entry.value) chipMap["batchNo"] = entry.value;
          // code block
          break;
        case "dateOfManufacture":
          if (entry.value !== "" && entry.value)
            chipMap["dateOfManufacture"] = entry.value;
          break;
        case "dateOfExpiry":
          if (entry.value !== "" && entry.value)
            chipMap["dateOfExpiry"] = entry.value;
          break;
        case "stateIds":
          if (entry.value !== "" && entry.value)
            chipMap["stateIds"] = entry.value;
          break;
      }
    });
    setChipsState(chipMap);
  };


  const deleteChip = (chipKey, chipValue) => {
    var json = filterDataRedux.searchCriteriaList;
    var json1 = structuredClone(json);

    if (chipKey === "batchNo") {
      json1[0].value = "";
    }
    if (chipKey === "dateOfManufacture") {
      setFilterData({ ...filterData, mnfStartDate: "", mnfEndDate: "" });

      json1[1].value = "";
    }
    if (chipKey === "dateOfExpiry") {
      setFilterData({ ...filterData, expStartDate: "", expEndDate: "" });

      json1[2].value = "";
    }
    if (chipKey === "stateIds") {
      const index = json1[3].value.indexOf(chipValue);
      if (index > -1) {
        json1[3].value.splice(index, 1);
      }
      setFilterStates(json1[3].value);
    }

    if (json1[3].value.length == 0) json1[3].value = [null];

    dispatch({
      type: "UPDATE_FILTER",
      data: json1,
    });
  };

  const [openDispatch, setOpenDispatch] = useState(false)

  return (
    <div>
      {loader ? (
        <Box className="main-content-overlay" sx={{ height: "100vh" }}>
          <CircularProgress />
        </Box>
      ) : (
        <div>
          <Box className="main-content-overlay">
            <div className="main-content-div">
              <Typography
                align="center"
                mt={2}
                variant="h5"
                sx={{ color: MAIN_BLUE }}
              >
                {translate('inventoryDataElements')}
              </Typography>
              <Box className="form-div">
                <Grid container>
                  <Grid item md={3} sd={12} p={1}>
                    <Typography variant="h6" sx={{ color: MAIN_BLUE, mx: 1 , textTransform:"capitalize"}}>
                      {heading?.replace("-", " ") + " " + translate('inventory')} 
                    </Typography>
                  </Grid>
                  <Grid item md={5}>
                    <Stack direction="row" sx={{ my: 1.5, flexWrap: "wrap" }}>
                      {Object.keys(chipsState).map((key) => (
                        <DeletableChips
                          chipValue={chipsState[key]}
                          chipKey={key}
                          deleteChip={deleteChip}
                          options={options}
                          sx={{ maxHeight: "2rem !important" }}
                        />
                      ))}
                    </Stack>
                  </Grid>
                  <Grid item md={4} sx={{ display: 'flex', alignItems: 'center' }}>
                      { isWarehouseProcured && showDispatchButtonForNftb && (openDispatch ? (
                        <div>
                          <Dialog open={openDispatch} 
                          onClose={()=> handleCloseDispatch(false)}

                          PaperProps={{
                            sx: { minWidth: "80%",  },
                          }}>

                            <DispatchLotComponent handleCloseDispatch={handleCloseDispatch} categoryId={params.categoryId}></DispatchLotComponent>
                          </Dialog>
                        </div>
                      ) : (
                        <Button variant="contained" onClick={() => { setOpenDispatch(true) }}>Dispatch</Button>
                      ))}
                    <SearchBar
                      onChange={(e) => {
                        if (e !== "") {
                          setFiltersSearch(e);
                        } else {
                          removeSearch();
                        }
                      }}
                    />
                    {openFilter ? (
                      <div>
                        <SimpleDialogDemo
                          chipsState={chipsState}
                          open={openFilter}
                          onClose={handleClose}
                          filterData={filterData}
                          setFilterData={setFilterData}
                          setFilters={setFilters}
                          filterStates={filterStates}
                          setActiveFilters={setActiveFilters}
                          activeFilters={activeFilters}
                          stateId={stateId}
                          setStateId={setStateId}
                          handleSelectChange={handleSelectChange}
                          options={options}
                          fetchStateList={fetchStateList}
                        />
                      </div>
                    ) : (
                      <FilterButton handleClickOpen={handleClickOpen} />
                    )}

                  </Grid>
                </Grid>
                <Box sx={{ m: 1 }}>
                  <GridItem
                    height="main"
                    onGridReady={onGridReady}
                    rowData={rowbatchData}
                    gridRef={gridRef}
                    rowSelection={RowSelectionType}
                    columnDefs={columnDefsBatches}
                    defaultColDef={defaultColDef}
                    pageSize={pageSize}
                    pageSizeArray={PAGE_SIZE_ARRAY}
                    count={counter()}
                    setPage={setPage}
                    page={pageNo}
                    onSelectionChanged={onRowClicked}
                    showDownloadButton={true}
                    fetchLotOrBatch={fetchLotOrBatch}
                    filterDataRedux={filterDataRedux}
                  />
                </Box>
              </Box>
            </div>
          </Box>
        </div>
      )}
    </div>
  );
};

export default InventoryPage;

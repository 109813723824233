import React, { useEffect, useState } from "react";
// import VendorDetailsSchema from "./VendorDetailsSchema.json";
import useVendorDetailsSchemaFn from "./VendorDetailsSchema";
import VendorDetailsUiSchema from "./VendorDetailsUiSchema.json";
// import VendorDetailsSchemaEdit from "./VendorDetailsSchemaEdit.json";
import useVendorDetailsSchemaEditFn from "./VendorDetailsSchemaEdit";
import VendorDetailsUiSchemaEdit from "./VendorDetailsUiSchemaEdit.json";
import "./VendorDetails.css";
import { Box, Button } from "@mui/material";
import validator from "@rjsf/validator-ajv8";
import QrImage from "../QrImage/QrImage";
import { Form } from "@rjsf/mui";
import { useParams } from "react-router-dom";
import VendorDetailsForm from "./VendorDetailsForm";
import { set } from "lodash/fp";
import VendorLicenseDetails from "./VendorLicenseDetails";
import { useAppDispatch } from "../../../services/StoreHooks";
import useTranslatewrapper from "../../../components/useTranslateWrapper";
function VendorDetails({
  VendorData,
  getId,
  qrData,
  formData,
  setFormData,
  handleData,
  disableVendorData,
  independentBatch,
  setShowButton,
  vendorList,
  categoryId,
  fetchVendorList,
  setVendorList,
  hideAddVendorButton,
  isPremixSelfAdd,
  isInspector
}) {

  const {translate} = useTranslatewrapper()

  const vendorDetailsSchemaObj = useVendorDetailsSchemaFn()
  const VendorDetailsSchemaEdit = useVendorDetailsSchemaEditFn()

  const dispatch = useAppDispatch();
  const dispatchShowLoader = (showLoader) => {
    dispatch({
      type: "SHOW_LOADER",
      data: {
        showLoader
      },
    });
  };
  const [vendorDataDropdown, setVendorDataDropdown] = useState({});
  const [addnewVendor, setaddnewVendor] = useState(false);
  const [vendorDetailsUiSchema, setvendorDetailsUiSchema] = useState();
  const [VendorDetailsSchemastate, setVendorDetailsSchemastate] = useState(useVendorDetailsSchemaFn());
  const [openSearchByLicenseNumber, setOpenSearchByLicenseNumber] = useState(false)
  const removeSubmitButton = true;
  const params = useParams();

  useEffect(() => {
    if (independentBatch) {
      VendorDetailsUiSchemaEdit["accreditedByAgency"] = {
        "ui:widget": "hidden",
        "ui:classNames": "accredited",
      };
      VendorDetailsUiSchemaEdit["licenseNumber"] = {
        // "ui:widget": "hidden",
      };
      VendorDetailsUiSchema["accreditedByAgency"] = {
        "ui:widget": "hidden",
        "ui:classNames": "accredited",
      };
      VendorDetailsUiSchema["licenseNumber"] = {
        "ui:disabled": true,
      };
      VendorDetailsUiSchema["completeAddress"] = {
        "ui:classNames": "tool-tip",
        "ui:disabled": true,
        "ui:help": VendorData?.completeAddress || vendorDataDropdown?.completeAddress || '',
      };
      VendorDetailsUiSchemaEdit["completeAddress"] = {
        "ui:classNames": "tool-tip",
        "ui:help": VendorData?.completeAddress,
        "ui:disabled": true,
      };
      if (!disableVendorData) {
        VendorDetailsUiSchema.vendorType["ui:disabled"] = true;
        let newSchema = set(
          ["properties", "name"],
          {
            type: "string",
            title: translate('nameOfVendor'),
            enumNames: vendorList.map((item) => item?.name),
            enum: vendorList.map((item) => item?.id),
          },
          vendorDetailsSchemaObj
        );
        setVendorDetailsSchemastate(newSchema);
      }
      
    } else {
      VendorDetailsUiSchema["accreditedByAgency"] = {
        "ui:classNames": "accredited",
      };
      VendorDetailsUiSchema["licenseNumber"] = {};
      VendorDetailsUiSchemaEdit["accreditedByAgency"] = {
        "ui:classNames": "accredited",
      };
      VendorDetailsUiSchemaEdit["completeAddress"]={"ui:widget": "textarea"};
      VendorDetailsUiSchema["completeAddress"]={"ui:widget": "textarea"};
      VendorDetailsUiSchemaEdit["licenseNumber"] = {};
      VendorDetailsUiSchema.vendorType["ui:disabled"] = false;
      VendorDetailsUiSchema.completeAddress["ui:disabled"] = false;
    }

    setvendorDetailsUiSchema(
      getId ? VendorDetailsUiSchemaEdit : VendorDetailsUiSchema
    );
  }, [independentBatch, vendorList]);


  const QrImageWidget = (props) => {
    // const imageRedux = useAppSelector((state) => state.qrImageState);
    return <QrImage qrData={qrData} props={props} />;
  };
  const widgets = {
    QrImageWidget,
  };
  // useEffect(() => {
  //   if (independentBatch) {
  //     vendorDetailsUiSchema["accreditedByAgency"] = { "ui:widget": "hidden" };
  //     var newUiSchema = set(
  //       ["accreditedByAgency", "ui:widget"],
  //       "hidden",
  //       vendorDetailsUiSchema
  //     );
  //     setvendorDetailsUiSchema(newUiSchema);
  //   }
  // }, [independentBatch]);
  //comment

  // const myTheme = {
  //   ...Theme

  // };
  // const ThemedForm = withTheme(myTheme);

  const handleClickOk = async (id, name) =>{
    // await fetchVendorList()
    let selectedVendor = vendorList.filter(
      (item) => item?.id == id
    )[0];
    if(!isPremixSelfAdd){
      dispatchNotification("success", [translate('vendorAddedToList')]);
    }
    let vendorObj = {}
    if (selectedVendor) {
      // let id = e.formData.name;
      let tempSelectedVendor = {...selectedVendor}
      vendorObj.name = selectedVendor.name;
      vendorObj.manufacturerId = selectedVendor.id;
      tempSelectedVendor.name = id;
      VendorDetailsUiSchema["completeAddress"]["ui:help"] =
        selectedVendor.completeAddress;
      setVendorDataDropdown(tempSelectedVendor);
      if (selectedVendor?.completeAddress || selectedVendor?.vendorType) {
        vendorObj.completeAddress = selectedVendor?.completeAddress;
        vendorObj.vendorType = selectedVendor?.vendorType;
        vendorObj.licenseNumber = selectedVendor?.licenseNumber;
        vendorObj.address = selectedVendor.address;
        vendorObj.accreditedByAgency = selectedVendor.accreditedByAgency;
      }
    }
    handleData(vendorObj);
  }
  const dispatchNotification = (typ, msg) => {
    dispatch({
      type: "SHOW_NOTIFICATION",
      data: {
        open: true,
        type: typ,
        message: msg,
      },
    });
  };

  return (
    <Box id="VendorDetails" className="form-div ">
      <VendorDetailsForm
        open={addnewVendor}
        setaddnewVendor={setaddnewVendor}
        categoryId={categoryId}
        fetchVendorList={fetchVendorList}
      ></VendorDetailsForm>
      <VendorLicenseDetails
      open={openSearchByLicenseNumber}
      setOpenSearchByLicenseNumber={setOpenSearchByLicenseNumber}
      categoryId={categoryId}
      handleClickOk={handleClickOk}
      fetchVendorList={fetchVendorList}
      isPremixSelfAdd={isPremixSelfAdd}
      handleData={handleData}
      vendorList={vendorList}
      setVendorList={setVendorList}
      >

      </VendorLicenseDetails>

      <Box p={2} id="rjsf">
        {vendorDetailsUiSchema && (
          <Form
            schema={(independentBatch && (!disableVendorData)) ? VendorDetailsSchemastate : VendorDetailsSchemaEdit}
            validator={validator}
            uiSchema={vendorDetailsUiSchema}
            formData={(independentBatch && (!disableVendorData)) ? vendorDataDropdown : VendorData}
            widgets={widgets}
            children={removeSubmitButton || false}
            disabled={disableVendorData || isInspector || getId || (!independentBatch && !getId) || isPremixSelfAdd}
            className="vendor-detail-styling"
            onChange={(e) => {
              // debugger;
              // setFormData(e.formData);
              if (independentBatch && !disableVendorData) {
                let selectedVendor = vendorList.filter((item) => item?.id == e.formData.name)[0];
                if (selectedVendor) {
                  let id = e.formData.name;
                  e.formData.name = selectedVendor.name;
                  e.formData.manufacturerId = selectedVendor.id;
                  selectedVendor.name = id;
                  VendorDetailsUiSchema["completeAddress"]["ui:help"] = selectedVendor.completeAddress;
                  setVendorDataDropdown(selectedVendor);
                  if (selectedVendor?.completeAddress || selectedVendor?.vendorType) {
                    e.formData.completeAddress = selectedVendor?.completeAddress;
                    e.formData.vendorType = selectedVendor?.vendorType;
                    e.formData.licenseNumber = selectedVendor?.licenseNumber;
                    e.formData.address = selectedVendor?.address;
                  }
                }
              }
              handleData(e.formData);
              setShowButton(true);
            }}
            // onSubmit={(e) => {
            //   sendFormData(e.formData);
            // }}
          ></Form>
        )}
        {independentBatch && (!disableVendorData) && (
          <>
            <Button
              onClick={(e) => {
                setaddnewVendor(true);
              }}
              hidden={hideAddVendorButton}
            >
              {translate('addNewVendor')}
            </Button>
            <Button
            disabled
            onClick={(e)=>{
              setOpenSearchByLicenseNumber(true)
            }}
            >
              {translate('vendorWithFssaiLicenseNo')}
            </Button>
            </>
          )}
      </Box>
    </Box>
  );
}

export default VendorDetails;

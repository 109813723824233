import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import useTranslatewrapper from "../../components/useTranslateWrapper";

function BatchAndVendorInfo({ isInspector }) {
  const {translate} = useTranslatewrapper()
  const routeParams = useParams();
  const [requiredID, setRequiredID] = React.useState("");
  const [QRBatchDetails, setQRBatchDetails] = React.useState();
  const [batchDetails, setBatchDetails] = React.useState({});
  const FORTIFICATION_URL = process.env.REACT_APP_BASE_FORTIFICATION_URL;
  const [vendorDetails, setVendorDetails] = React.useState({});
  const [title, setTitle] = React.useState("")

  useEffect(() => {
    if (routeParams.uuid) {
      getUUIDData();
    }
  }, []);
  let percentagePremix;
  let tempBatchDetails = {};
  let tempVendorDetails = {};
  let QRCodeData;
  const getUUIDData =  () => {
     fetch(`${FORTIFICATION_URL}${routeParams?.categoryId}/${routeParams?.type}/code/${routeParams?.uuid}/details`)
    .then(response => response.json())
    .then(data => {
      console.log(data, "data from fetch")
      QRCodeData = data;
      setRequiredID(QRCodeData?.id);
      setQRBatchDetails(QRCodeData);
      console.log(QRBatchDetails);
      let batchPropLocation;

      if(routeParams.type=="batch"){batchPropLocation = QRCodeData?.batchProperties}
      else if (routeParams.type=="lot"){batchPropLocation = QRCodeData?.batch.batchProperties}
      batchPropLocation.map((newData) => {
  
        switch (newData.name) {
          case "manufacture_name":
            tempVendorDetails["name"] = newData.value;
            break;
          case "manufacture_type":
            tempVendorDetails["vendorType"] = newData.value;
            break;
          case "manufacture_accreditedByAgency":
            tempVendorDetails["isManufacturerAccreditedByAnyAgency"] =
              newData.value ? 'Yes' : 'No';
            break;
          case "manufacture_licenseNumber":
            tempVendorDetails["fssaiLicense"] = newData.value;
            break;
          case "manufacture_completeAddress":
            tempVendorDetails["address"] = newData.value;
  
            break;
          case "percentage_of_premix_required":
            percentagePremix =
              newData.value && newData.value !== "undefined"
                ? Number(newData.value)
                : "";
            tempBatchDetails["percentToBeUsedInMix"] = percentagePremix;
            break;
          case "manufacture_batchNumber":
            // debugger;
              tempBatchDetails["UserDefinedBatchNo"] = newData.value;
              break;
          case "raw_material_type":
            // debugger;
              tempBatchDetails["RawMaterialType"] = newData.value;
              break;
          case "batch_name":
            tempBatchDetails["name"] = newData.value;
            break;
        }
        setVendorDetails(tempVendorDetails);
      });

      if(routeParams.type=="batch"){tempBatchDetails["batchNumber"] = QRCodeData.batchNo}
      else if (routeParams.type=="lot"){tempBatchDetails["batchNumber"] = QRCodeData.batch.batchNo}
      

      setBatchDetails({
        ...tempBatchDetails,
        manufacturingDate: QRCodeData.dateOfManufacture,
        expiryDate: QRCodeData.dateOfExpiry,
        totalQuantityInKgs: QRCodeData.totalQuantity,
        remainingQuantityInKgs: QRCodeData.remainingQuantity,
      });
    })

    let pathname = window.location.pathname
    
    let capitalizedWord = pathname.split('/')[1].toUpperCase()

    if(capitalizedWord == "MILLER"){
      capitalizedWord = "FORTIFIED RICE"
    }
    
    setTitle(capitalizedWord + " "+routeParams?.type.toUpperCase() + " DETAILS")
  };

  return (
    <div style={{ fontSize: "1rem", padding: "10px", }}>
      <div style={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
      <div>
      <img src={'/assets/images/Sri-Lanka-Government.jpg'} alt="path logo" width={180} style={{objectFit:'cover', marginLeft: '12px'}} />
      </div>
      <div style={{textAlign:'center', flexGrow:'1'}}>
      <h2 style={{color: '#228B22', fontWeight: "bolder"}}>FoRTrace</h2>
      <h2 >{title}</h2>
      </div>
      </div>
       {/* marginTop: "20px" */}
      <div style={{ padding: "10px" }}>
        <h3>{translate('vendorDetails')}</h3>
        <Grid container className="Gridclass" spacing={0.5}>
          <Grid item md={6} sm={12}>
            <strong>Name of the Vendor</strong> : {vendorDetails["name"]}
          </Grid>
          <Grid item md={6} sm={12}>
            <strong>License</strong> : {vendorDetails["fssaiLicense"]}
          </Grid>
          <Grid item md={6} sm={12}>
            <strong>Vendor Type</strong> : {vendorDetails["vendorType"]}
          </Grid>
          <Grid item md={6} sm={12}>
            <strong>Manufacturer accredited by an agency?</strong> :
            {vendorDetails["isManufacturerAccreditedByAnyAgency"]}
          </Grid>
          <Grid item md={6} sm={12}>
            <strong>Address</strong> : {vendorDetails["address"]}
          </Grid>
        </Grid>
      </div>
      <div style={{ padding: "10px" }}>
        <h3>
          {routeParams?.type == "batch" ? "Batch Details" : "Lot Details"}
        </h3>
        <Grid container spacing={0.5}>
          <Grid item md={6} sm={12}>
            <strong>Name</strong> : {batchDetails["name"]}
          </Grid>
          <Grid item md={6} sm={12}>
            <strong>Batch Number</strong> : {batchDetails["batchNumber"]}
          </Grid>
          <Grid item md={6} sm={12}>
            <strong>Manufacturing Date(yyyy-mm-dd)</strong> :{" "}
            {batchDetails["manufacturingDate"]}
          </Grid>
          <Grid item md={6} sm={12}>
            <strong>Expiry Date(yyyy-mm-dd)</strong> :{" "}
            {batchDetails["expiryDate"]}
          </Grid>
          {batchDetails["percentToBeUsedInMix"] && (
            <Grid item md={6} sm={12}>
              <strong>% to be used in mix</strong> :{" "}
              {batchDetails["percentToBeUsedInMix"]}
            </Grid>
          )}
          <Grid item md={6} sm={12}>
            <strong>Total Quantity(in kgs)</strong> :{" "}
            {batchDetails["totalQuantityInKgs"]}
          </Grid>
          <Grid item md={6} sm={12}>
            <strong>Remaining Quantity(in kgs)</strong> :{" "}
            {batchDetails["remainingQuantityInKgs"]}
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default BatchAndVendorInfo;

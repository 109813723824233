import React, { useEffect, useMemo, useState } from "react";
import { useParams , useLocation} from "react-router";
import BarChartIcon from "@mui/icons-material/BarChart";
import UnitConverterWeight from "../../../utils/UnitConverter";
import { Stack, Tooltip, Typography } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { getDashboardDataForIndividualCell, getDashboardEmpaneledDataForIndividualCell } from "../../../services/DashBoardService";
import { MILLER_UI_TEXT } from "../../../textConstant";
import { useAppSelector } from "../../../services/StoreHooks";

function DataCard(props) {
    const { dashboardData , onCardClick, masterCardClicked , dashboardType, params, type, setRowData, scrollToTable,
        //  formattedfromDate, formattedtoDate, 
        startDate, endDate,
         selectedTab, query, setQuery, gridlevel} = props

    const [cardsData, setCardsData] = useState([]);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    // const view = queryParams.get('view');
    const view = type;

    const categoryNameToIdSelectorRedux = useAppSelector(
      (state) => state?.categoriesData?.categories
    );

    const categoryNameToIdMapRedux = useMemo(() => {
      return categoryNameToIdSelectorRedux.reduce((acc, current) => {
        acc[current.name] = current.id.toString();
        return acc;
      }, {});
    }, [categoryNameToIdSelectorRedux]);

    const categoryNameToIdMap = {
      premix: categoryNameToIdMapRedux["PREMIX"],
      frk: categoryNameToIdMapRedux["FRK"],
      miller: categoryNameToIdMapRedux["MILLER"],
    };

    useEffect(() => {
        const data = []
        // console.log(dashboardData, 'dashboard data')
        if("PREMIX" in dashboardData){
            data.push({
                id:dashboardData["PREMIX"]?.categoryId,
                name: "PREMIX",
                quantity: getQuantity(dashboardData["PREMIX"]),
                clicked: checkLabOrProduction() && params.categoryname?.toLowerCase() === 'premix',
                footerText: view== 'lab' ? 'Premix Tested': 'Premix Production',
                tooltip: view== 'lab' ? 'Total Tested': 'Total produced and inproduced quantity',
                totalApproved: dashboardData["PREMIX"].batchTestApprovedQuantity || 0, 
                totalRejected: dashboardData["PREMIX"].batchTestRejectedQuantity || 0, 
                lotTestApprovedQuantity: dashboardData["PREMIX"].lotTestApprovedQuantity || 0,
                lotTestRejectedQuantity: dashboardData["PREMIX"].lotTestRejectedQuantity || 0,
                totalProduction: dashboardData["PREMIX"].totalProduction,
                dispatchedApproved: dashboardData["PREMIX"].approvedQuantity,
                availableTested: dashboardData["PREMIX"].availableTested,
                availableNonTested: dashboardData["PREMIX"].availableNotTested,
                  totalTested: dashboardData["PREMIX"].batchTested,
                dispatchedLotsApproved: dashboardData["PREMIX"].approvedQuantity,
                dispatchedLotsRejected: dashboardData["PREMIX"].lotRejected,
                dispatchedLotsTransit: dashboardData["PREMIX"].lotInTransit,
                notDispatched: dashboardData["PREMIX"].availableTested,
                batchRejected: dashboardData["PREMIX"].batchRejected,
                approvedEmpanel: dashboardData["PREMIX"].approved,
                rejectedEmpanel: dashboardData["PREMIX"].rejected,
                transitEmpanel: dashboardData["PREMIX"].transit,
                totalDispatched: dashboardData["PREMIX"].totalDispatched,
                batchApproved: dashboardData["PREMIX"].batchApproved,
                sampleInTransit: dashboardData['PREMIX'].sampleInTransit,
                usedQuantity: dashboardData['PREMIX'].usedQuantity,
                noOfBatches: dashboardData['PREMIX'].noOfBatches,
                batchNotTested: dashboardData['PREMIX'].batchNotTested
            });

        }
        if("FRK" in dashboardData){
            data.push({
                id:dashboardData["FRK"]?.categoryId,
                name: "FRK",
                quantity: getQuantity(dashboardData["FRK"]),
                clicked: checkLabOrProduction() && params.categoryname?.toLowerCase() === 'frk',
                footerText: view== 'lab' ? 'FRK Tested': 'FRK Production',
                tooltip: view== 'lab' ? 'Total Tested': 'Total produced and inproduced quantity',
                totalApproved: dashboardData["FRK"].batchTestApprovedQuantity || 0, 
                totalRejected: dashboardData["FRK"].batchTestRejectedQuantity || 0, 
                lotTestApprovedQuantity: dashboardData["FRK"].lotTestApprovedQuantity || 0,
                lotTestRejectedQuantity: dashboardData["FRK"].lotTestRejectedQuantity || 0,
                totalProduction: dashboardData["FRK"].totalProduction,
                dispatchedApproved: dashboardData["FRK"].approvedQuantity,
                availableTested: dashboardData["FRK"].availableTested,
                availableNonTested: dashboardData["FRK"].availableNotTested,
                totalTested: dashboardData["FRK"].batchTested,
                dispatchedLotsApproved: dashboardData["FRK"].approvedQuantity,
                dispatchedLotsRejected: dashboardData["FRK"].lotRejected,
                dispatchedLotsTransit: dashboardData["FRK"].lotInTransit,
                notDispatched: dashboardData["FRK"].availableTested,
                batchRejected: dashboardData["FRK"].batchRejected,
                approvedEmpanel: dashboardData["FRK"].approved,
                rejectedEmpanel: dashboardData["FRK"].rejected,
                transitEmpanel: dashboardData["FRK"].transit,
                totalDispatched: dashboardData["FRK"].totalDispatched,
                batchApproved: dashboardData["FRK"].batchApproved,
                sampleInTransit: dashboardData['FRK'].sampleInTransit,
                usedQuantity: dashboardData['FRK'].usedQuantity,
                noOfBatches: dashboardData['FRK'].noOfBatches,
                batchNotTested: dashboardData['FRK'].batchNotTested

            });

        }
        if("MILLER" in dashboardData){
            data.push({
                id:dashboardData["MILLER"]?.categoryId,
                name: "MILLER",
                quantity: getQuantity(dashboardData["MILLER"]),
                clicked: checkLabOrProduction() && params.categoryname?.toLowerCase() === 'miller',
                footerText: view== 'lab' ? 'Fortified Rice Tested': 'Fortified Rice Production',
                tooltip: view== 'lab' ? 'Total Tested': 'Total produced and inproduced quantity',
                totalApproved: dashboardData["MILLER"].batchTestApprovedQuantity || 0, 
                totalRejected: dashboardData["MILLER"].batchTestRejectedQuantity || 0, 
                lotTestApprovedQuantity: dashboardData["MILLER"].lotTestApprovedQuantity || 0,
                lotTestRejectedQuantity: dashboardData["MILLER"].lotTestRejectedQuantity || 0,
                totalProduction: dashboardData["MILLER"].totalProduction,
                dispatchedApproved: dashboardData["MILLER"].approvedQuantity,
                availableTested: dashboardData["MILLER"].availableTested,
                availableNonTested: dashboardData["MILLER"].availableNotTested,
                totalTested: dashboardData["MILLER"].batchTested,
                dispatchedLotsApproved: dashboardData["MILLER"].approvedQuantity,
                dispatchedLotsRejected: dashboardData["MILLER"].lotRejected,
                dispatchedLotsTransit: dashboardData["MILLER"].lotInTransit,
                notDispatched: dashboardData["MILLER"].availableTested,
                batchRejected: dashboardData["MILLER"].batchRejected,
                approvedEmpanel: dashboardData["MILLER"].approved,
                rejectedEmpanel: dashboardData["MILLER"].rejected,
                transitEmpanel: dashboardData["MILLER"].transit,
                totalDispatched: dashboardData["MILLER"].totalDispatched,
                batchApproved: dashboardData["MILLER"].batchApproved,
                sampleInTransit: dashboardData['MILLER'].sampleInTransit,
                usedQuantity: dashboardData['MILLER'].usedQuantity,
                noOfBatches: dashboardData['MILLER'].noOfBatches,
                batchNotTested: dashboardData['MILLER'].batchNotTested

            });

        }
        if("WAREHOUSE" in dashboardData){
            // debugger;
            data.push({
                id:dashboardData["WAREHOUSE"]?.categoryId,
                name: "WAREHOUSE",
                FRK: {
                    id:parseInt(categoryNameToIdMapRedux['FRK']),
                    name: "WAREHOUSE",
                    clicked: params.categoryname?.toLowerCase() === 'warehouse' && params.categoryId==categoryNameToIdMapRedux['FRK'] && dashboardType=="production",
                    footerText: view== 'lab' ? 'Total Tested': 'Total Availablity',
                    tooltip: view== 'lab' ? 'Total Tested': 'Total quantity available',
                    totalApproved: dashboardData["WAREHOUSE"].batchTestApprovedQuantity, 
                    totalRejected: dashboardData["WAREHOUSE"].batchTestRejectedQuantity, 
                    lotTestApprovedQuantity: dashboardData["WAREHOUSE"].lotTestApprovedQuantity,
                    lotTestRejectedQuantity: dashboardData["WAREHOUSE"].lotTestRejectedQuantity ,
                    acceptedLots: dashboardData["WAREHOUSE"].FRK?.acceptedLots || 0,
                    rejectedLots: dashboardData["WAREHOUSE"].FRK?.rejectedLots || 0,
                    dispatchedLots: dashboardData["WAREHOUSE"].FRK?.dispatchedLots || 0,
                    availableLots:dashboardData["WAREHOUSE"].FRK?.availableLots || 0},
                MILLER:{
                    id:categoryNameToIdMapRedux['MILLER'],
                    name: "WAREHOUSE",
                    clicked: params.categoryname?.toLowerCase() === 'warehouse' && params.categoryId==categoryNameToIdMapRedux['MILLER'] && dashboardType=="production",
                    footerText: view== 'lab' ? 'Total Tested': 'Total Availablity',
                    tooltip: view== 'lab' ? 'Total Tested': 'Total quantity available',
                    totalApproved: dashboardData["WAREHOUSE"].batchTestApprovedQuantity, 
                    totalRejected: dashboardData["WAREHOUSE"].batchTestRejectedQuantity, 
                    lotTestApprovedQuantity: dashboardData["WAREHOUSE"].lotTestApprovedQuantity,
                    lotTestRejectedQuantity: dashboardData["WAREHOUSE"].lotTestRejectedQuantity ,
                    acceptedLots: dashboardData["WAREHOUSE"].MILLER?.acceptedLots || 0,
                    rejectedLots: dashboardData["WAREHOUSE"].MILLER?.rejectedLots || 0,
                    dispatchedLots: dashboardData["WAREHOUSE"].MILLER?.dispatchedLots || 0,
                    availableLots:dashboardData["WAREHOUSE"].MILLER?.availableLots || 0 
                }

            });

        }

        setCardsData(data)
    }, [dashboardData]);

    useEffect(()=>{
        // debugger
        // cardsData.forEach(d=>d.clicked = false);
        setCardsData(cardsData => cardsData.map(item=>({...item, clicked: false})))
    }, [masterCardClicked, selectedTab]);
    const checkLabOrProduction = ()=> {
        return ((view=== 'production' && dashboardType==='production')  || ((view=== 'lab' || view === 'empanel') && dashboardType==='testing') || 
        (view==='warehouse'));
    }
    const getQuantity = (item, name)=>{
        if(view == 'lab'){
            return UnitConverterWeight(((item?.totalTestedQuantity||0) + (item?.totalLotTestedQuantity||0))  || 0)
        }
        if(name === 'WAREHOUSE'&& view !=='lab'){
            return UnitConverterWeight(item?.inProductionQuantity)
        }
        return UnitConverterWeight((item?.inProductionQuantity||0) + (item?.producedQuantity||0))
    }


    const handleClick = (id, categoryName, clicked) => {
      if (clicked || categoryName == "WAREHOUSE") {
        return;
      }
      setCardsData((prevDivs) =>
        prevDivs.map((div) =>
          div.id === id
            ? { ...div, clicked: !div.clicked }
            : { ...div, clicked: false }
        )
      );
      onCardClick(clicked ? "" : id, clicked ? "all" : categoryName);
    };

    const handleWarehouseClick = (id, categoryName, clicked) => {
      if (clicked) {
        return;
      }
      onCardClick(id, categoryName);
    };

    const getCellSpecificData = async (catId, cellType, dataType) =>{
        if(dataType=='warehouse'){
            return
        }
    
        setQuery({
            ...query,
            type: 'agency-all',
            categoryId: catId,
            cellType: cellType,
            level: queryParams.get('level') || gridlevel.level,
            geoId: queryParams.get('geoId') || gridlevel.geoId,
            dataType: dataType,
            fromDate: startDate,
            toDate: endDate
        })
        
    //   const dataFromApi = await getDashboardDataForIndividualCell(catId, cellType,queryParams.get('level'), queryParams.get('geoId'), dataType , formattedfromDate, formattedtoDate)
    //   setRowData(dataFromApi.data)
    //     if(scrollToTable){
    //         scrollToTable()
    //     }
    }

    const getCellSpecificEmpaneledData = async(catId, cellType) =>{
        // const dataFromApi = await getDashboardEmpaneledDataForIndividualCell(catId, cellType, formattedfromDate, formattedtoDate)
        // setRowData(dataFromApi.data)
        //   if(scrollToTable){
        //       scrollToTable()
        //   }

          setQuery({
            ...query,
            type: 'agency-all',
            categoryId: catId,
            cellType: cellType,
            level: queryParams.get('level') || gridlevel.level,
            geoId: queryParams.get('geoId') || gridlevel.geoId,
            dataType: 'empanel',
            fromDate: startDate,
            toDate: endDate
        })


    }

    const targetNameMapping = {
        "PREMIX": 'FRK',
        "FRK": 'Miller',
        "MILLER": '',
        'wAREHOUSE': ''
    }

    const categoryNameMap = {
        'PREMIX' : 'Premix',
        'FRK': 'FRK',
        'MILLER': 'FR'
    }

    const dispatchedToTextMapping = {
        "PREMIX" : 'Premix',
        "FRK": 'FRK',
        'MILLER': 'to Warehouse'
    }

    return (
        <div className="statsLine" >
            <div className="fourboxLine">
                {cardsData.map((data) => (
                    
                    <div 
                    key={data.id}
                    className={`innerboxLine ${data.name} ${data.clicked && 'active'}`}
                    onClick={() => handleClick(data.id, data.name, data.clicked)}
                    style={{
                        // color: 'white', 
                    // minWidth: '20vw',
                    // width: '500px'
                    }}
                     >
                        
                        <div className="header">
                            <Typography align="center" fontSize={'14px'}>
                                {data.name == "MILLER" ? 'FR' : data.name}
                            </Typography> 

                        </div>
                        {
                            view == 'production' && 

                            <Stack direction="column" flexGrow={'1'}>
                            <Stack direction="row" 
                            >
                                <Typography className="display-avatar-center"  align="center" sx={{borderRight: '1px solid white', borderBottom: '1px solid white'}} fontSize={'12px'} width={data.name === 'MILLER' ? '20%' : '18%'}> <Stack> Total Production <b>(#{data.noOfBatches})</b></Stack></Typography>
                                <Typography className="display-avatar-center"  align="center" sx={{borderRight: '1px solid white', borderBottom: '1px solid white'}} fontSize={'12px'} width={'16%'}> {`Available ${categoryNameMap[data.name]}`}</Typography>
                                <Stack fontSize={'12px'} width={data.name === 'MILLER' ? '64%' : '50%'}>
    
                                    <Typography align="center" sx={{borderRight: data.name === 'MILLER' ? '' : '1px solid white', borderBottom: '1px solid white'}} fontSize={'12px'} >{`Dispatched ${dispatchedToTextMapping[data.name]} `}<b>({UnitConverterWeight(data.totalDispatched)})</b></Typography>
                                    <Stack direction="row"  flexGrow={1}>
                                        <Typography className="display-avatar-center"  align="center" sx={{borderRight: '1px solid white', borderBottom: '1px solid white'}} fontSize={'12px'} width={'34%'}> In-Transit</Typography>
                                        <Typography className="display-avatar-center"  align="center" sx={{borderRight: '1px solid white', borderBottom: '1px solid white'}} fontSize={'12px'} width={'33%'}> Accepted</Typography>
                                        <Typography className="display-avatar-center"  align="center" sx={{borderRight: data.name === 'MILLER' ? '' : '1px solid white', borderBottom: '1px solid white'}}  fontSize={'12px'} width={'33%'}>Rejected</Typography>
                                
                                    </Stack>
                                </Stack>
                                { data.name !== 'MILLER' && 
                                <Typography  className="display-avatar-center" align="center" sx={{borderBottom: '1px solid white', wordWrap:'normal'}}  fontSize={'12px'} width={'16%'}>{`Consumed by ${targetNameMapping[data.name]}`}</Typography>
                                }
                            </Stack>
    
                            <Stack direction="row" color={'black'} minHeight={'40px'} sx={{flexGrow:1}}  >
                                <Typography fontWeight={'600'}   className="display-avatar-center fw-bold hoverLink"  align="center" sx={{borderRight: '1px solid white', borderBottom: '1px solid white'}} fontSize={'12px'} width={data.name === 'MILLER' ? '20%' : '18%'} onClick={()=>{getCellSpecificData(data.id, 'totalProduction', 'production')}}> {UnitConverterWeight(data.totalProduction)}</Typography>
                                <Typography className="display-avatar-center fw-bold hoverLink"  align="center" sx={{borderRight: '1px solid white', borderBottom: '1px solid white'}} fontSize={'12px'} width={'16%'} onClick={()=>{getCellSpecificData(data.id, 'availableTested', 'production')}}>{UnitConverterWeight(data.availableTested)}</Typography>
                                <Stack direction="row"  fontSize={'12px'} width={data.name === 'MILLER' ? '64%' : '50%'}>
                                    <Typography className="display-avatar-center fw-bold hoverLink"  align="center" sx={{borderRight: '1px solid white', borderBottom: '1px solid white'}}  fontSize={'12px'} width={'34%'} onClick={()=>{getCellSpecificData(data.id, 'transitQuantity', 'production')}}>{UnitConverterWeight(data.dispatchedLotsTransit)}</Typography>
                                    <Typography className="display-avatar-center fw-bold hoverLink"  align="center" sx={{borderRight: '1px solid white', borderBottom: '1px solid white'}}  fontSize={'12px'} width={'33%'} onClick={()=>{getCellSpecificData(data.id, 'approvedQuantity', 'production')}}>{UnitConverterWeight(data.dispatchedLotsApproved)}</Typography>
                                    <Typography className="display-avatar-center fw-bold hoverLink"  align="center" sx={{borderRight: data.name === 'MILLER' ? '' : '1px solid white', borderBottom: '1px solid white'}}  fontSize={'12px'} width={'33%'} onClick={()=>{getCellSpecificData(data.id, 'lotRejected', 'production')}}>{UnitConverterWeight(data.dispatchedLotsRejected)}</Typography>
                                
                                </Stack>
                                {data.name !== 'MILLER' && 
                                    <Typography  className="display-avatar-center fw-bold hoverLink" align="center" sx={{borderBottom: '1px solid white', wordWrap:'break-word'}}  fontSize={'12px'} width={'16%'} onClick={()=>{getCellSpecificData(data.id, 'usedQuantity', 'production')}}>{UnitConverterWeight(data.usedQuantity)}</Typography>
                                }
                            </Stack>

    
                               
                            </Stack>

                        // <Stack direction="column" flexGrow={'1'}>
                        // <Stack direction="row" 
                        // // sx={{wordBreak: 'break-all'}}
                        // >
                        //     <Typography className="display-avatar-center"  align="center" sx={{borderRight: '1px solid white', borderBottom: '1px solid white'}} fontSize={'12px'} width={'25%'}>  Total Production</Typography>
                        //     <Typography align="center" sx={{borderRight: '1px solid white', borderBottom: '1px solid white', wordWrap:'normal'}}  fontSize={'12px'} width={'30%'}>Consumption / Dispatched Approved</Typography>
                        //     <Stack fontSize={'12px'} width={'45%'}>

                        //         <Typography align="center" sx={{borderBottom: '1px solid white'}} fontSize={'12px'} > Available</Typography>
                        //         <Stack direction="row"  flexGrow={1}>
                        //             <Typography className="display-avatar-center"  align="center" sx={{borderRight: '1px solid white', borderBottom: '1px solid white'}} fontSize={'12px'} width={'50%'}>  Tested</Typography>
                        //             <Typography className="display-avatar-center"  align="center" sx={{borderBottom: '1px solid white'}}  fontSize={'12px'} width={'50%'}>Non-Tested</Typography>
                            
                        //         </Stack>
                        //     </Stack>
                        // </Stack>

                        // <Stack direction="row" color={'black'} minHeight={'40px'} sx={{wordBreak: 'break-all', flexGrow:1}}  >
                        //     <Typography fontWeight={'600'}   className="display-avatar-center fw-bold hoverLink"  align="center" sx={{borderRight: '1px solid white', borderBottom: '1px solid white'}} fontSize={'12px'} width={'25%'} onClick={()=>{getCellSpecificData(data.id, 'totalProduction', 'production')}}> {UnitConverterWeight(data.totalProduction)}</Typography>
                        //     <Typography  className="display-avatar-center fw-bold hoverLink" align="center" sx={{borderRight: '1px solid white', borderBottom: '1px solid white', wordWrap:'break-word'}}  fontSize={'12px'} width={'30%'} onClick={()=>{getCellSpecificData(data.id, 'approvedQuantity', 'production')}}>{UnitConverterWeight(data.dispatchedApproved)}</Typography>
                        //     <Stack direction="row"  fontSize={'12px'} width={'45%'}>
                        //         <Typography className="display-avatar-center fw-bold hoverLink"  align="center" sx={{borderRight: '1px solid white', borderBottom: '1px solid white'}} fontSize={'12px'} width={'50%'} onClick={()=>{getCellSpecificData(data.id, 'availableTested', 'production')}}>{UnitConverterWeight(data.availableTested)}</Typography>
                        //         <Typography className="display-avatar-center fw-bold hoverLink"  align="center" sx={{borderBottom: '1px solid white'}}  fontSize={'12px'} width={'50%'} onClick={()=>{getCellSpecificData(data.id, 'availableNotTested', 'production')}}>{UnitConverterWeight(data.availableNonTested)}</Typography>
                            
                        //     </Stack>
                        // </Stack>

                        // {/* <Stack direction="row" color={'black'} minHeight={'40px'} sx={{wordBreak: 'break-all', flexGrow:1}} >
                        //     <Typography fontWeight={'600'}   className="display-avatar-center fw-bold"  align="center" sx={{borderRight: '1px solid white', borderBottom: '1px solid white'}} fontSize={'12px'} width={'25%'}>  #</Typography>
                        //     <Typography  className="display-avatar-center fw-bold" align="center" sx={{borderRight: '1px solid white', borderBottom: '1px solid white'}}  fontSize={'12px'} width={'30%'}>#</Typography>
                        //     <Stack direction="row"  fontSize={'12px'} width={'45%'}>
                        //         <Typography className="display-avatar-center fw-bold"  align="center" sx={{borderRight: '1px solid white', borderBottom: '1px solid white'}} fontSize={'12px'} width={'50%'}>  #</Typography>
                        //         <Typography className="display-avatar-center fw-bold"  align="center" sx={{borderBottom: '1px solid white'}}  fontSize={'12px'} width={'50%'}>#</Typography>
                            
                        //     </Stack>
                        // </Stack> */}


                           
                        // </Stack>
                        }
                        {/* testing */}
                        {
                            view == 'lab' && 

                            <Stack direction="column" flexGrow={'1'}>
                            <Stack direction="row" 
                            // sx={{wordBreak: 'break-all'}}
                            >
                                {(data.name !== 'MILLER') &&
                                    <Typography className="display-avatar-center"  align="center" sx={{borderRight: '1px solid white', borderBottom: '1px solid white', wordBreak: 'keep-all'}} fontSize={'12px'} width={'25%'}>In-Transit for Testing</Typography>
                                }
                                <Stack fontSize={'12px'} width={data.name === 'MILLER' ? '70%' : '50%'}>
                                    <Typography className="display-avatar-center" minHeight={'40px'} align="center" sx={{borderBottom: '1px solid white', borderRight: '1px solid white'}} fontSize={'12px'} >Tested Quantity <b className="hoverLink" onClick={()=>{getCellSpecificData(data.id, 'batchTestedQuantity', 'testing')}}> ({UnitConverterWeight(data.totalTested)})</b></Typography>
                                    <Stack direction="row"   flexGrow={1}>
                                        <Typography className="display-avatar-center" align="center" minHeight={'40px'} sx={{borderBottom: '1px solid white', borderRight: '1px solid white'}} fontSize={'12px'} width={'50%'} >Approved</Typography>
                                        <Typography className="display-avatar-center"  align="center" sx={{ borderBottom: '1px solid white', borderRight: '1px solid white'}} fontSize={'12px'} width={'50%'}>Rejected</Typography>
                                    </Stack>
                                </Stack>
                                <Typography width={data.name === 'MILLER' ? '30%' : '25%'} className="display-avatar-center"  align="center" sx={{borderBottom: '1px solid white', wordBreak: 'keep-all'}} fontSize={'12px'}>Not Tested Quantity</Typography>
                            </Stack>

                            <Stack direction="row" color={'black'} minHeight={'40px'} sx={{wordBreak: 'break-all', flexGrow:1}}  >
                                {(data.name !== 'MILLER') &&
                                    <Typography className="display-avatar-center fw-bold hoverLink"  align="center" sx={{borderRight: '1px solid white', borderBottom: '1px solid white'}} fontSize={'12px'} width={'25%'} onClick={()=>{getCellSpecificData(data.id, 'sampleInTransit', 'testing')}}>  {UnitConverterWeight(data.sampleInTransit)}</Typography>
                                }
                                <Stack direction="row" fontSize={'12px'} width={data.name === 'MILLER' ? '70%' : '50%'}>
                                        <Typography className="display-avatar-center fw-bold hoverLink" align="center" sx={{borderRight: '1px solid white', borderBottom: '1px solid white'}} fontSize={'12px'} width={'50%'} onClick={()=>{getCellSpecificData(data.id, 'batchTestApprovedQuantity', 'testing')}}>  {UnitConverterWeight(data.batchApproved)}</Typography>
                                        <Typography className="display-avatar-center fw-bold hoverLink" align="center" sx={{borderRight: '1px solid white', borderBottom: '1px solid white'}}  fontSize={'12px'} width={'50%'} onClick={()=>{getCellSpecificData(data.id, 'batchTestRejectedQuantity', 'testing')}}>{UnitConverterWeight(data.batchRejected)}</Typography>
                                </Stack>
                                <Typography width={data.name === 'MILLER' ? '30%' : '25%'} className="display-avatar-center fw-bold hoverLink"  align="center" sx={{borderBottom: '1px solid white'}} fontSize={'12px'} onClick={()=>{getCellSpecificData(data.id, 'batchNotTestedQuantity', 'testing')}}>  {UnitConverterWeight(data.batchNotTested)}</Typography>

                            </Stack>
                     
                        </Stack>

                        // <Stack direction="column" flexGrow={'1'}>
                        //     <Stack direction="row" 
                        //     sx={{wordBreak: 'break-all'}}
                        //     >
                        //         <Typography className="display-avatar-center"  align="center" sx={{borderRight: '1px solid white', borderBottom: '1px solid white', wordBreak: 'keep-all'}} fontSize={'12px'} width={'15%'}>  Total Tested</Typography>
                        //         <Stack sx={{borderRight: '1px solid white'}} fontSize={'12px'} width={'70%'}>

                        //             <Typography className="display-avatar-center" minHeight={'40px'} align="center" sx={{borderBottom: '1px solid white'}} fontSize={'12px'} > Batch Approved <b>({UnitConverterWeight(data.batchApproved)})</b></Typography>
                        //             <Stack direction="row"   flexGrow={1}>
                        //                 <Stack  width={'75%'}  sx={{borderRight: '1px solid white'}} flexGrow={1}>
                        //                     <Typography className="display-avatar-center" align="center" minHeight={'40px'} sx={{borderBottom: '1px solid white'}} fontSize={'12px'} > Dispatched Lots <b>({UnitConverterWeight(data.totalDispatched)})</b></Typography>

                        //                     <Stack direction={'row'}>

                        //                         <Typography className="display-avatar-center"  align="center" sx={{borderRight: '1px solid white', borderBottom: '1px solid white'}} fontSize={'12px'} width={'33.7%'}>Approved</Typography>
                        //                         <Typography className="display-avatar-center"  align="center" sx={{borderRight: '1px solid white', borderBottom: '1px solid white'}}  fontSize={'12px'} width={'33.7%'}>Rejected</Typography>
                        //                         <Typography className="display-avatar-center"  align="center" sx={{borderBottom: '1px solid white'}}  fontSize={'12px'} width={'32.6%'}>Transit</Typography>

                        //                     </Stack>
                                
                        //                 </Stack>
                        //                 <Typography className="display-avatar-center"  align="center" sx={{ borderBottom: '1px solid white'}} fontSize={'12px'} width={'25%'}>  Not Dispatched</Typography>
                        //             </Stack>
                        //         </Stack>
                        //         <Typography className="display-avatar-center" align="center" sx={{borderBottom: '1px solid white', wordBreak: 'keep-all'}}  fontSize={'12px'} width={'15%'}>Batch Rejected</Typography>
                        //     </Stack>

                        //     <Stack direction="row" color={'black'} minHeight={'40px'} sx={{wordBreak: 'break-all', flexGrow:1}}  >
                        //         <Typography className="display-avatar-center fw-bold hoverLink"  align="center" sx={{borderRight: '1px solid white', borderBottom: '1px solid white'}} fontSize={'12px'} width={'15%'} onClick={()=>{getCellSpecificData(data.id, 'batchTestedQuantity', 'testing')}}>  {UnitConverterWeight(data.totalTested)}</Typography>
                        //         <Stack sx={{borderRight: '1px solid white'}} fontSize={'12px'} width={'70%'}>

                        //             <Stack direction="row"   flexGrow={1}>
                        //                 <Stack direction={'row'} width={'75%'}  sx={{borderRight: '1px solid white'}} flexGrow={1}>


                        //                         <Typography className="display-avatar-center fw-bold hoverLink"  align="center" sx={{borderRight: '1px solid white', borderBottom: '1px solid white'}} fontSize={'12px'} width={'33.7%'} onClick={()=>{getCellSpecificData(data.id, 'approvedQuantity', 'testing')}}>  {UnitConverterWeight(data.dispatchedLotsApproved)}</Typography>
                        //                         <Typography className="display-avatar-center fw-bold hoverLink"  align="center" sx={{borderRight: '1px solid white', borderBottom: '1px solid white'}} fontSize={'12px'} width={'33.7%'} onClick={()=>{getCellSpecificData(data.id, 'lotRejected', 'testing')}}>{UnitConverterWeight(data.dispatchedLotsRejected)}</Typography>
                        //                         <Typography className="display-avatar-center fw-bold hoverLink"  align="center" sx={{borderBottom: '1px solid white'}} fontSize={'12px'} width={'32.6%'} onClick={()=>{getCellSpecificData(data.id, 'transitQuantity', 'testing')}}>{UnitConverterWeight(data.dispatchedLotsTransit)}</Typography>
                        //                 </Stack>
                        //                 <Typography className="display-avatar-center fw-bold hoverLink"  align="center" sx={{ borderBottom: '1px solid white'}} fontSize={'12px'} width={'25%'} onClick={()=>{getCellSpecificData(data.id, 'availableTested', 'testing')}}>  {UnitConverterWeight(data.notDispatched)}</Typography>
                        //             </Stack>
                        //         </Stack>
                        //         <Typography className="display-avatar-center fw-bold hoverLink" align="center" sx={{borderBottom: '1px solid white', wordWrap:'break-word'}}  fontSize={'12px'} width={'15%'} onClick={()=>{getCellSpecificData(data.id, 'batchTestRejectedQuantity', 'testing')}}>{UnitConverterWeight(data.batchRejected)}</Typography>
                        //     </Stack>

                        //     {/* <Stack direction="row" color={'black'} minHeight={'40px'} sx={{wordBreak: 'break-all', flexGrow:1}}  >
                        //         <Typography className="display-avatar-center fw-bold"  align="center" sx={{borderRight: '1px solid white', borderBottom: '1px solid white'}} fontSize={'12px'} width={'15%'}>  #</Typography>
                        //         <Stack sx={{borderRight: '1px solid white'}} fontSize={'12px'} width={'70%'}>

                        //             <Stack direction="row"   flexGrow={1}>
                        //                 <Stack direction={'row'} width={'75%'}  sx={{borderRight: '1px solid white'}} flexGrow={1}>


                        //                         <Typography className="display-avatar-center fw-bold"  align="center" sx={{borderRight: '1px solid white', borderBottom: '1px solid white'}} fontSize={'12px'} width={'33.7%'}>  #</Typography>
                        //                         <Typography className="display-avatar-center fw-bold"  align="center" sx={{borderRight: '1px solid white', borderBottom: '1px solid white'}} fontSize={'12px'} width={'33.7%'}>  #</Typography>
                        //                         <Typography className="display-avatar-center fw-bold"  align="center" sx={{borderBottom: '1px solid white'}}  fontSize={'12px'} width={'32.6%'}>#</Typography>
                                
                        //                 </Stack>
                        //                 <Typography className="display-avatar-center fw-bold"  align="center" sx={{ borderBottom: '1px solid white'}} fontSize={'12px'} width={'25%'}>  #</Typography>
                        //             </Stack>
                        //         </Stack>
                        //         <Typography className="display-avatar-center fw-bold" align="center" sx={{borderBottom: '1px solid white', wordWrap:'break-word'}}  fontSize={'12px'} width={'15%'}>#</Typography>
                        //     </Stack> */}


                           
                        // </Stack>
                        }
                        {
                            view=='empanel' && 
                            <Stack direction="column" flexGrow={'1'}>
                                <Typography className="display-avatar-center"  align="center" minHeight={'30px'} sx={{borderBottom: '1px solid white'}} fontSize={'12px'}>Dispatched Lots({UnitConverterWeight(data.approvedEmpanel + data.rejectedEmpanel + data.transitEmpanel)})</Typography>
                                <Stack minHeight={'30px'} direction={'row'} width={'100%'}flexGrow={1}>
                                    <Typography className="display-avatar-center"  align="center" sx={{borderRight: '1px solid white', borderBottom: '1px solid white'}} fontSize={'12px'} width={'33%'}> Approved</Typography>
                                    <Typography className="display-avatar-center"  align="center" sx={{ borderRight: '1px solid white',borderBottom: '1px solid white'}} fontSize={'12px'} width={'33%'}> Rejected</Typography>
                                    <Typography className="display-avatar-center"  align="center" sx={{ borderBottom: '1px solid white'}} fontSize={'12px'} width={'34%'}> Transit</Typography>
                                </Stack>
                                <Stack color={'black'} minHeight={'40px'} direction={'row'} width={'100%'} flexGrow={1}>
                                    <Typography className="display-avatar-center fw-bold hoverLink"  align="center" onClick={()=>{getCellSpecificEmpaneledData(data.id, 'approvedQuantity')}} sx={{ borderRight: '1px solid white', borderBottom: '1px solid white'}} fontSize={'12px'} width={'33%'}> {UnitConverterWeight(data.approvedEmpanel)}</Typography>
                                    <Typography className="display-avatar-center fw-bold hoverLink"  align="center" onClick={()=>{getCellSpecificEmpaneledData(data.id, 'lotRejected')}} sx={{ borderRight: '1px solid white', borderBottom: '1px solid white'}} fontSize={'12px'} width={'33%'}> {UnitConverterWeight(data.rejectedEmpanel)}</Typography>
                                    <Typography className="display-avatar-center fw-bold hoverLink"  align="center" onClick={()=>{getCellSpecificEmpaneledData(data.id, 'transitQuantity')}} sx={{ borderBottom: '1px solid white'}} fontSize={'12px'} width={'34%'}> {UnitConverterWeight(data.transitEmpanel)}</Typography>
                                </Stack>
                                {/* <Stack color={'black'} minHeight={'40px'} direction={'row'} width={'100%'} flexGrow={1}>
                                    <Typography className="display-avatar-center fw-bold"  align="center" sx={{borderRight: '1px solid white', borderBottom: '1px solid white'}} fontSize={'12px'} width={'33%'}> #</Typography>
                                    <Typography className="display-avatar-center fw-bold"  align="center" sx={{ borderRight: '1px solid white',borderBottom: '1px solid white'}} fontSize={'12px'} width={'33%'}> #</Typography>
                                    <Typography className="display-avatar-center fw-bold"  align="center" sx={{borderBottom: '1px solid white'}} fontSize={'12px'} width={'34%'}> #</Typography>
                                </Stack> */}
                            </Stack>
                        }

                        {
                            view == "warehouse" && 
                        <Stack direction="column" color={'black'} display={'flex'}>
                            <Typography className="display-avatar-center"  align="center" minHeight={'30px'} sx={{borderBottom: '1px solid white'}} fontSize={'12px'}>Recieved Lots ({UnitConverterWeight(data.FRK.acceptedLots + data.FRK.rejectedLots + data.MILLER.acceptedLots + data.MILLER.rejectedLots)})</Typography>
                            <Stack
                            direction="row" color={'black'} display={'flex'}
                            >
                            <div className={`innerboxLine ${data.name} ${data.FRK.clicked && 'active'}`} style={{cursor:"pointer"}} onClick={()=>{handleWarehouseClick(data.FRK.id,data.name,data.FRK.clicked)}}>
                            <Typography className="display-avatar-center"  align="center" minHeight={'30px'} sx={{borderRight: '1px solid white',borderBottom: '1px solid white'}} fontSize={'12px'}>FRK ({UnitConverterWeight(data.FRK.acceptedLots + data.FRK.rejectedLots)})</Typography>
                            <Stack minHeight={'30px'} direction={'row'} width={'100%'}>
                                <Typography className="display-avatar-center"  align="center" sx={{borderRight: '1px solid white', borderBottom: '1px solid white'}} fontSize={'12px'} width={'25%'}> Approved</Typography>
                                <Typography className="display-avatar-center"  align="center" sx={{ borderRight: '1px solid white',borderBottom: '1px solid white'}} fontSize={'12px'} width={'25%'}> Rejected</Typography>
                                <Typography className="display-avatar-center"  align="center" sx={{ borderRight: '1px solid white',borderBottom: '1px solid white'}} fontSize={'12px'} width={'25%'}> Dispatched</Typography>
                                <Typography className="display-avatar-center"  align="center" sx={{ borderRight: '1px solid white',borderBottom: '1px solid white'}} fontSize={'12px'} width={'25%'}> Available</Typography>
                            </Stack>
                            <Stack color={'black'} minHeight={'40px'} direction={'row'} width={'100%'}>
                                <Typography className="display-avatar-center fw-bold"  align="center" sx={{ borderRight: '1px solid white', borderBottom: '1px solid white'}} fontSize={'12px'} width={'25%'} onClick={()=>{getCellSpecificData(data.FRK.id, 'accepted', 'warehouse')}}> {UnitConverterWeight(data.FRK.acceptedLots)}</Typography>
                                <Typography className="display-avatar-center fw-bold"  align="center" sx={{ borderRight: '1px solid white', borderBottom: '1px solid white'}} fontSize={'12px'} width={'25%'} onClick={()=>{getCellSpecificData(data.FRK.id, 'rejected', 'warehouse')}}> {UnitConverterWeight(data.FRK.rejectedLots)}</Typography>
                                <Typography className="display-avatar-center fw-bold"  align="center" sx={{ borderRight: '1px solid white', borderBottom: '1px solid white'}} fontSize={'12px'} width={'25%'} onClick={()=>{getCellSpecificData(data.FRK.id, 'dispatched', 'warehouse')}}> {UnitConverterWeight(data.FRK.dispatchedLots)}</Typography>
                                <Typography className="display-avatar-center fw-bold"  align="center" sx={{ borderRight: '1px solid white',borderBottom: '1px solid white'}} fontSize={'12px'} width={'25%'} onClick={()=>{getCellSpecificData(data.FRK.id, 'available', 'warehouse')}}> {UnitConverterWeight(data.FRK.availableLots)}</Typography>
                            </Stack>
                            {/* <Stack color={'black'} minHeight={'40px'} direction={'row'} width={'100%'} >
                                <Typography className="display-avatar-center fw-bold"  align="center" sx={{borderRight: '1px solid white', borderBottom: '1px solid white'}} fontSize={'12px'} width={'25%'}> #</Typography>
                                <Typography className="display-avatar-center fw-bold"  align="center" sx={{ borderRight: '1px solid white',borderBottom: '1px solid white'}} fontSize={'12px'} width={'25%'}> #</Typography>
                                <Typography className="display-avatar-center fw-bold"  align="center" sx={{ borderRight: '1px solid white',borderBottom: '1px solid white'}} fontSize={'12px'} width={'25%'}> #</Typography>
                                <Typography className="display-avatar-center fw-bold"  align="center" sx={{borderBottom: '1px solid white'}} fontSize={'12px'} width={'25%'}> #</Typography>
                            </Stack> */}
                            </div>
                            <div className={`innerboxLine ${data.name} ${data.MILLER.clicked && 'active'}`} style={{cursor:"pointer"}}onClick={()=>{handleWarehouseClick(data.MILLER.id,data.name,data.MILLER.clicked)}}>
                            <Typography className="display-avatar-center"  align="center" minHeight={'30px'} sx={{borderBottom: '1px solid white'}} fontSize={'12px'}>{MILLER_UI_TEXT} ({UnitConverterWeight(data.MILLER.acceptedLots + data.MILLER.rejectedLots)})</Typography>
                            <Stack minHeight={'30px'} direction={'row'} width={'100%'}>
                                <Typography className="display-avatar-center"  align="center" sx={{borderRight: '1px solid white', borderBottom: '1px solid white'}} fontSize={'12px'} width={'25%'}> Approved</Typography>
                                <Typography className="display-avatar-center"  align="center" sx={{ borderRight: '1px solid white',borderBottom: '1px solid white'}} fontSize={'12px'} width={'25%'}> Rejected</Typography>
                                <Typography className="display-avatar-center"  align="center" sx={{ borderRight: '1px solid white',borderBottom: '1px solid white'}} fontSize={'12px'} width={'25%'}> Dispatched</Typography>
                                <Typography className="display-avatar-center"  align="center" sx={{ borderBottom: '1px solid white'}} fontSize={'12px'} width={'25%'}> Available</Typography>
                            </Stack>
                            <Stack color={'black'} minHeight={'40px'} direction={'row'} width={'100%'}>
                                <Typography className="display-avatar-center fw-bold"  align="center" sx={{ borderRight: '1px solid white', borderBottom: '1px solid white'}} fontSize={'12px'} width={'25%'} onClick={()=>{getCellSpecificData(data.MILLER.id, 'accepted', 'warehouse')}}> {UnitConverterWeight(data.MILLER.acceptedLots)}</Typography>
                                <Typography className="display-avatar-center fw-bold"  align="center" sx={{ borderRight: '1px solid white', borderBottom: '1px solid white'}} fontSize={'12px'} width={'25%'} onClick={()=>{getCellSpecificData(data.MILLER.id, 'rejected', 'warehouse')}}> {UnitConverterWeight(data.MILLER.rejectedLots)}</Typography>
                                <Typography className="display-avatar-center fw-bold"  align="center" sx={{ borderRight: '1px solid white', borderBottom: '1px solid white'}} fontSize={'12px'} width={'25%'} onClick={()=>{getCellSpecificData(data.MILLER.id, 'dispatched', 'warehouse')}}> {UnitConverterWeight(data.MILLER.dispatchedLots)}</Typography>
                                <Typography className="display-avatar-center fw-bold"  align="center" sx={{ borderBottom: '1px solid white'}} fontSize={'12px'} width={'25%'} onClick={()=>{getCellSpecificData(data.MILLER.id, 'available', 'warehouse')}}> {UnitConverterWeight(data.MILLER.availableLots)}</Typography>
                            </Stack>
                            {/* <Stack color={'black'} minHeight={'40px'} direction={'row'} width={'100%'} >
                                <Typography className="display-avatar-center fw-bold"  align="center" sx={{borderRight: '1px solid white', borderBottom: '1px solid white'}} fontSize={'12px'} width={'25%'}> #</Typography>
                                <Typography className="display-avatar-center fw-bold"  align="center" sx={{ borderRight: '1px solid white',borderBottom: '1px solid white'}} fontSize={'12px'} width={'25%'}> #</Typography>
                                <Typography className="display-avatar-center fw-bold"  align="center" sx={{ borderRight: '1px solid white',borderBottom: '1px solid white'}} fontSize={'12px'} width={'25%'}> #</Typography>
                                <Typography className="display-avatar-center fw-bold"  align="center" sx={{borderBottom: '1px solid white'}} fontSize={'12px'} width={'25%'}> #</Typography>
                            </Stack> */}
                            </div>
                            </Stack>
                        </Stack>
                        }



                        {/* <div className={`textboxLine `}>
                            <div>
                                <div className="dashboard-card-text-grey">
                                    <b>{data.quantity}</b> <Tooltip sx={{marginTop: "-5px"}} placement="right-start" title={data.tooltip}>
                                    <InfoIcon />
                        </Tooltip>
                                </div>
                            </div>
                            {view !== 'lab' && <div>
                            <BarChartIcon fontSize="large"></BarChartIcon>
                            </div>}

                        </div>
                        {view != 'lab' && <div
                            className="bottom-div"
                           
                        > {data.footerText}
                        </div>}
                        {view == 'lab' && data.name !== 'WAREHOUSE'  && <div class="d-flex dashboard-card-subtext-grey text-center border-top">
                            <div class="flex-grow-1 py-2 border-right"> Batch Test Approved 
                               <div className="font-weight-bolder">{UnitConverterWeight(data.totalApproved)}</div></div>
                            <div class="flex-grow-1 py-2"> Batch Test Rejected <div className="font-weight-bolder">{UnitConverterWeight(data.totalRejected)}</div> </div>
                        </div>}
                        {view == 'lab'  && <div class="d-flex dashboard-card-subtext-grey text-center border-top">
                            <div class="flex-grow-1 py-2 border-right"> Lot Test Approved 
                               <div className="font-weight-bolder">{UnitConverterWeight(data.lotTestApprovedQuantity)}</div></div>
                            <div class="flex-grow-1 py-2"> Lot Test Rejected <div className="font-weight-bolder">{UnitConverterWeight(data.lotTestRejectedQuantity)}</div> </div>
                        </div>} */}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default DataCard;
